import { useState, useEffect, useMemo } from 'react';
/**
 * DOCs: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * @param {RefObject} ref
 * @returns a bool
 */
function useOnVisible({ ref, options = {} }) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observerOptions = useMemo(
    () => ({
      threshold: 1.0,
      rootMargin: '0px',
      ...options,
    }),
    [options]
  );

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        observerOptions
      ),
    [observerOptions]
  );

  useEffect(() => {
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
}

export default useOnVisible;
