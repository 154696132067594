import Intro, {
  Title,
  Container,
  DownArrow,
  DownArrowWrapper,
  DownButton,
  Logo,
  LogoWrapper,
  ModelName,
} from './Intro';

export default Intro;
export const IntroTitle = Title;
export const IntroContainer = Container;
export const IntroDownArrow = DownArrow;
export const IntroDownArrowWrapper = DownArrowWrapper;
export const IntroDownButton = DownButton;
export const IntroLogo = Logo;
export const IntroLogoWrapper = LogoWrapper;
export const IntroModelName = ModelName;
