import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useDemoData } from '../../../hooks';
import Carousel from '../../../models/CarouselModel';

const parseCarouselData = demoData => {
  const data = new Carousel(demoData || {}).props;
  return data;
};

export const connect = WrappedComponent => props => {
  const [currentCarouselData, setCurrentCarouselData] = useState({});
  const demoData = useDemoData();

  useEffect(() => {
    if (!isEmpty(demoData)) setCurrentCarouselData(parseCarouselData(demoData));
    return () => {};
  }, [demoData]);

  return <WrappedComponent {...currentCarouselData} {...props} />;
};

export default connect;
