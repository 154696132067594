/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import axios from 'axios';

// export const fetchStaticData = (baseUrl, id, ipAddress) =>
//   axios.get(
//     `${baseUrl}/external/static_resources/${id}?resource_type=product_demo&ip_address=${ipAddress}`
//   );
export const fetchData = (baseUrl, id) =>
  axios.get(`${baseUrl}/product_demo_animations/${id}/localized_demo`);

export const fetchExperienceMock = (baseUrl, UUID) =>
  axios.get('./mockResponse.json', {
    params: {
      baseUrl,
      UUID,
    },
  });

export default fetchExperienceMock;
