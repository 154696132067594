import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { ProductCardType } from '../../types';
import AllProducts from './AllProducts';
import StillNotSure from './StillNotSure';

const ViewAllProducts = ({
  show, // flag to show or hide End Screen Section (CMS)
  title,
  products,
  // data-show-view-all="true" attribute defined when a QR url is opened
  // See irobot-experiences repo
  showViewAll,
  compareProductsUrl,
  takeQuizUrl,
}) => {
  const [isAllProdsVisible, setIsAllProdsVisible] = useState(false);
  const showStillNotSure =
    showViewAll &&
    show &&
    (!!products.length || !!compareProductsUrl || !!takeQuizUrl);
  return (
    showStillNotSure && (
      <Container>
        <StillNotSureWrapper className={isAllProdsVisible ? 'is-animated' : ''}>
          <StillNotSure
            onViewAllClick={() => {
              setIsAllProdsVisible(true);
            }}
            showViewAll={!!products.length}
          />
        </StillNotSureWrapper>
        <AllProductsWrapper isVisible={isAllProdsVisible}>
          <AllProducts
            title={title}
            products={products}
            hideStillNotSure={!compareProductsUrl && !takeQuizUrl}
          />
        </AllProductsWrapper>
      </Container>
    )
  );
};

const fadeOutAnimation = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
  z-index:1;
}
`;

const Container = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.mobileRT.getWidthValue(401)}px;
`;

const StillNotSureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  &.is-animated {
    animation-name: ${fadeOutAnimation};
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
`;

const AllProductsWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  height: ${({ isVisible }) => (isVisible ? 'auto' : 0)};
  overflow: hidden;
  transition: opacity 1s linear;
`;

ViewAllProducts.propTypes = {
  title: PropTypes.string,
  compareProductsUrl: PropTypes.string,
  takeQuizUrl: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(ProductCardType)),
  showViewAll: PropTypes.bool,
  show: PropTypes.bool,
};

ViewAllProducts.defaultProps = {
  title: '',
  compareProductsUrl: '',
  takeQuizUrl: '',
  products: [],
  showViewAll: false,
  show: false,
};

export default ViewAllProducts;
