import React from 'react';
import PropTypes from 'prop-types';
import S9Detail from './S9Detail';
import J7Detail from './J7Detail';
import J7UpdateDetail from './J7UpdateDetail';
import J8Detail from './J8Detail';
import I7Detail from './I7Detail';
import I3Detail from './I3Detail';
import I1Detail from './I1Detail';
import M6Detail from './M6Detail';
import I4Detail from './I4Detail';
import I5Detail from './I5Detail';
import GeniusCarousel from '../GeniusCarousel';
import TemplateComboI5Plus from '../templates/TemplateComboI5Plus';
import ResponsiveTools from '../../utils/responsive';
import TemplateComboJ5Plus from '../templates/TemplateComboJ5Plus';
import TemplateJ9Plus from '../templates/TemplateJ9Plus';
import TemplateComboJ9Plus from '../templates/TemplateComboJ9Plus';
import TemplateComboJ9PlusNoDock from '../templates/TemplateComboJ9PlusNoDock';
import TemplateOPPVacuum from '../templates/TemplateOPPVacuum';
import TemplateOPPCombo from '../templates/TemplateOPPCombo';
import { ID_TEXTS } from '../../utils/constants';
import TemplateOPPComboWhite from '../templates/TemplateOPPComboWhite';
import TemplateTopazMaxPlusBoulder from '../templates/TemplateTopazMaxPlusBoulder';
import TemplateOPPWhiteAutoEvac from '../templates/TemplateOPPComboWhiteAutoEvac';

const { isMobile } = ResponsiveTools;

const ModelDetail = props => {
  const renderModelDetail = () => {
    const { templateId } = props;
    let activeModel = null;

    switch (templateId) {
      case process.env.REACT_APP_S9_TYPE_ID:
        activeModel = <S9Detail {...props} />;
        break;
      case process.env.REACT_APP_J7_TYPE_ID:
        activeModel = <J7Detail {...props} />;
        break;
      case process.env.REACT_APP_I7_TYPE_ID:
        activeModel = <I7Detail {...props} />;
        break;
      case process.env.REACT_APP_I3_TYPE_ID:
        activeModel = <I3Detail {...props} />;
        break;
      case process.env.REACT_APP_I1_TYPE_ID:
        activeModel = <I1Detail {...props} />;
        break;
      case process.env.REACT_APP_M6_TYPE_ID:
        activeModel = <M6Detail {...props} />;
        break;
      case process.env.REACT_APP_I4_TYPE_ID:
        activeModel = <I4Detail {...props} />;
        break;
      case process.env.REACT_APP_I5_TYPE_ID:
        activeModel = <I5Detail {...props} />;
        break;
      case process.env.REACT_APP_J8_TYPE_ID:
        activeModel = <J8Detail {...props} />;
        break;
      case process.env.REACT_APP_J7_UPDATE_TYPE_ID:
        activeModel = <J7UpdateDetail {...props} />;
        break;
      case process.env.REACT_APP_COMBO_I5_PLUS_TYPE_ID:
        activeModel = <TemplateComboI5Plus {...props} />;
        break;
      case process.env.REACT_APP_COMBO_J5_PLUS_TYPE_ID:
        activeModel = <TemplateComboJ5Plus {...props} />;
        break;
      case process.env.REACT_APP_J9_PLUS_TYPE_ID:
        activeModel = <TemplateJ9Plus {...props} />;
        break;
      case process.env.REACT_APP_COMBO_J9_PLUS_TYPE_ID:
        activeModel = <TemplateComboJ9Plus {...props} />;
        break;
      case process.env.REACT_APP_COMBO_J9_PLUS_WO_DOCK_TYPE_ID:
        activeModel = <TemplateComboJ9PlusNoDock {...props} />;
        break;
      case process.env.REACT_APP_OPP_VACUUM_BLACK:
        activeModel = <TemplateOPPVacuum {...props} />;
        break;
      case process.env.REACT_APP_OPP_VACUUM_WHITE:
        activeModel = <TemplateOPPVacuum {...props} />;
        break;
      case process.env.REACT_APP_OPP_COMBO_BLACK:
        activeModel = <TemplateOPPCombo {...props} />;
        break;
      case process.env.REACT_APP_OPP_COMBO_WHITE:
        activeModel = <TemplateOPPComboWhite {...props} />;
        break;
      case process.env.REACT_APP_TOPAZ_MAX_PLUS_BOULDER:
        activeModel = <TemplateTopazMaxPlusBoulder {...props} />;
        break;
      case process.env.REACT_APP_OPP_COMBO_WHITE_AUTO_EVAC:
        activeModel = <TemplateOPPWhiteAutoEvac {...props} />;
        break;
      default:
        activeModel = null;
    }

    return activeModel;
  };

  return (
    <div id={ID_TEXTS.MODEL_DETAIL_WRAPPER}>
      {renderModelDetail()}
      <div id={ID_TEXTS.MAIN_CONTENT_REF}>
        {isMobile && <GeniusCarousel.GeniusCarouselTitle />}
      </div>
    </div>
  );
};

ModelDetail.propTypes = {
  templateId: PropTypes.string,
};

ModelDetail.defaultProps = {
  templateId: '',
};

export default ModelDetail;
