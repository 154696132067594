import { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useGetClientHeight() {
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    function handleWrapperChange() {
      setContainerHeight(document.documentElement.clientHeight);
    }
    handleWrapperChange();

    window.addEventListener('resize', handleWrapperChange);
    window.addEventListener('deviceorientation', handleWrapperChange);

    return () => {
      window.removeEventListener('resize', handleWrapperChange);
      window.removeEventListener('deviceorientation', handleWrapperChange);
    };
  }, []);

  return containerHeight;
}
