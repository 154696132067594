export const DESKTOP_DIMENSIONS = {
  width: 1920,
  height: 1907,
};

export const MOBILE_DIMENSIONS = {
  width: 375,
  height: 635,
};

export default {
  DESKTOP_SKETCH: DESKTOP_DIMENSIONS,
  MOBILE_SKETCH: MOBILE_DIMENSIONS,
};
