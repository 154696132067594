import ProductCardModel from './ProductCardModel';

export default class ViewAllProductsModel {
  constructor(viewAllProdsData = {}) {
    this.show = viewAllProdsData?.end_screen_show || false;
    this.showViewAll = viewAllProdsData?.showViewAll || false;
    this.title = viewAllProdsData?.end_title || 'Still not sure?';
    this.compareProductsUrl =
      viewAllProdsData?.product_demo_locale?.compare_products_link || '';
    this.takeQuizUrl = viewAllProdsData?.product_demo_locale?.quiz_link || '';
    this.products =
      this.parseProductsData(
        !!viewAllProdsData?.product_demo_locale?.product_demo_menus.length &&
          viewAllProdsData?.product_demo_locale?.product_demo_menus[0]
            .product_cards
      ) || [];
  }

  parseProductsData = (productsData = []) => {
    const parsedProds =
      !!productsData.length &&
      productsData.map(product => new ProductCardModel(product).props);
    return parsedProds;
  };

  get props() {
    return {
      show: this.show,
      showViewAll: this.showViewAll,
      title: this.title,
      compareProductsUrl: this.compareProductsUrl,
      takeQuizUrl: this.takeQuizUrl,
      products: this.products,
    };
  }
}
