import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Balancer } from 'react-wrap-balancer';
import { fontVariables } from '../../../styles/variables';
import { ReactComponent as ArrowDown } from '../../../assets/svgs/circle-arrow-down.svg';
import { normalizeScapedLineBreaks } from '../../../utils/string';
import Stack from './Stack';
import NavButton from './NavButton';
import device from '../../../utils/responsive/device';

const {
  families: { primaryFont },
  weights: { bold, normal },
} = fontVariables;

const Feature = ({
  icon,
  iconAlt,
  model,
  modelColor,
  modelFont,
  title,
  titleColor,
  titleFont,
  description,
  descriptionColor,
  footnote,
  accentColor,
  className,
  onNext,
  overlay,
}) => (
  <FeatureInnerStack spacing={1} overlay={overlay} className={className}>
    <IconWrapper>
      <Icon src={icon} alt={iconAlt} />
    </IconWrapper>
    <ModelName color={modelColor} font={modelFont}>
      {model}
    </ModelName>
    <Title color={titleColor} font={titleFont}>
      {normalizeScapedLineBreaks(title)}
    </Title>
    <Description color={descriptionColor} font={titleFont}>
      <Balancer>{normalizeScapedLineBreaks(description)}</Balancer>
    </Description>
    {footnote && (
      <Footnote color={descriptionColor} font={titleFont}>
        {normalizeScapedLineBreaks(footnote)}
      </Footnote>
    )}
    <div>
      <NavButton type="button" color={accentColor} onClick={onNext}>
        <ArrowDown />
      </NavButton>
    </div>
  </FeatureInnerStack>
);

Feature.propTypes = {
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  model: PropTypes.string,
  modelColor: PropTypes.string,
  modelFont: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  description: PropTypes.string,
  footnote: PropTypes.string,
  descriptionColor: PropTypes.string,
  accentColor: PropTypes.string,
  className: PropTypes.string,
  onNext: PropTypes.func,
  overlay: PropTypes.bool,
};

Feature.defaultProps = {
  icon: '',
  iconAlt: '',
  model: '',
  modelColor: '',
  modelFont: '',
  title: '',
  titleColor: '',
  titleFont: '',
  description: '',
  footnote: '',
  descriptionColor: '',
  accentColor: '',
  className: '',
  onNext: () => {},
  overlay: false,
};

const FeatureInnerStack = styled(Stack)`
  margin: 24px;
  padding: 24px;
  border-radius: 16px;
  background: ${({ overlay }) => (overlay ? 'rgba(0, 0, 0, 0.5)' : 'none')};

  @media ${device.tablet} {
    margin: 48px 24px;
  }

  @media ${device.desktop} {
    margin: 60px 24px;
  }

  @media ${device.xlarge} {
    padding: 48px 60px;
  }
`;

const IconWrapper = styled.div`
  max-height: 64px;

  @media ${device.xlarge} {
    height: 88px;
    max-height: 88px;
  }
`;

const Icon = styled.img`
  width: auto;
  max-width: 64px;
  max-height: 52px;

  @media ${device.xlarge} {
    height: 88px;
    max-height: 88px;
    max-width: 88px;
  }
`;

const ModelName = styled.div`
  font-family: ${({ font }) => font || primaryFont};
  font-weight: ${bold};
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.16;
  color: ${({ color }) => color};

  @media ${device.tablet} {
    font-size: 22px;
    letter-spacing: 0.32;
  }

  @media ${device.xlarge} {
    font-size: 44px;
  }
`;

const Title = styled.div`
  font-family: ${({ font }) => font || primaryFont};
  font-weight: ${normal};
  font-size: 30px;
  line-height: 1.1;
  letter-spacing: 0.32px;
  color: ${({ color }) => color};
  white-space: pre-wrap;

  @media ${device.tablet} {
    font-size: 44px;
    letter-spacing: 0.69;
  }

  @media ${device.xlarge} {
    font-size: 72px;
  }
`;

const Description = styled.div`
  font-family: ${({ font }) => font || primaryFont};
  font-weight: ${normal};
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: ${({ color }) => color};
  white-space: pre-wrap;

  @media ${device.tablet} {
    font-size: 24px;
    letter-spacing: 0.3;
  }

  @media ${device.xlarge} {
    font-size: 44px;
  }
`;

const Footnote = styled(Description)`
  font-family: ${({ font }) => font || primaryFont};
  color: ${({ color }) => color};
  font-size: 12px;
  line-height: 1.1;
  white-space: pre-wrap;

  @media ${device.desktop} {
    font-size: 16px;
    letter-spacing: 0.3;
  }

  @media ${device.xlarge} {
    font-size: 24px;
  }
`;

export const FeatureContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    max-width: 600px;
  }

  @media ${device.xlarge} {
    max-width: 900px;
  }
`;

export const FeatureStack = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 300px;
  opacity: 0;

  @media ${device.tablet} {
    max-width: 400px;
  }

  @media ${device.laptop} {
    max-width: 520px;
  }

  @media ${device.xlarge} {
    max-width: 760px;
  }
`;

export const FeatureSideStack = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  opacity: 0;

  justify-content: ${({ justify }) => justify ?? 'center'};
  padding: ${({ padding }) => padding ?? '0'};

  @media ${device.tablet} {
    max-width: 600px;
  }

  @media ${device.laptop} {
    max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  }

  @media ${device.xlarge} {
    max-width: 760px;
  }
`;

export const FeatureStackCentered = styled(FeatureStack)`
  justify-content: center;
`;

export const FeatureItem = styled.div`
  flex: 1 1 50%;
`;

export const FeatureItemCentered = styled(FeatureItem)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FeatureItemBottom = styled(FeatureItem)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const FeatureBottom = styled(Feature)`
  margin-top: 0;
  margin-bottom: 60px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  @media ${device.desktop} {
    margin-top: 0;
  }
`;

export default Feature;
