import styled from 'styled-components';

const ScrollContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

export default ScrollContainer;
