import uniqueId from 'uniqid';

let session = null;

const trackDemoLoad = (pixel = () => {}, demoSource = '', productName = '') => {
  pixel('event', `demoLoaded`, {
    session,
    product: productName,
    demoSource,
  });
};

const trackButtonClick = (
  pixel = () => {},
  buttonName = '',
  productName = ''
) => {
  pixel('event', 'buttonClick', {
    session,
    product: productName,
    buttonName,
  });
};

const trackCategoryShow = (
  pixel = () => {},
  activeCategory = '',
  demoSource = '',
  productName = ''
) => {
  pixel('event', `categoryExposure`, {
    session,
    product: productName,
    activeCategory,
    demoSource,
  });
};

const onDemoLoad = (pixel = () => {}, demoSource = '', productName = '') => {
  try {
    session = uniqueId();
    trackDemoLoad(pixel, demoSource, productName);
  } catch (error) {
    throw new Error(error);
  }
};

const onButtonClick = (pixel = () => {}, buttonName = '', productName = '') => {
  try {
    trackButtonClick(pixel, buttonName, productName);
  } catch (error) {
    throw new Error(error);
  }
};

const onCategoryShow = (
  pixel = () => {},
  activeCategory = '',
  demoSource = '',
  productName = ''
) => {
  try {
    trackCategoryShow(pixel, activeCategory, demoSource, productName);
  } catch (error) {
    throw new Error(error);
  }
};

const pixel = {
  onDemoLoad,
  onButtonClick,
  onCategoryShow,
};

export default pixel;
