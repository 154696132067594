import styled from 'styled-components';
import device from '../../../utils/responsive/device';

export const ImageContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: ${({ height }) => height}px;
`;

export const ImageBaseContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 0;
  width: 100%;
  height: ${({ height }) => height}px;
`;

export const ImageController = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    width: ${({ height }) => height}px;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
`;

export const SlideImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const SlideImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35%;
  height: auto;
  transform: translate(-50%, -50%);
`;

export const ColorLine = styled.div`
  position: absolute;
  z-index: 1;
  top: 100px;
  left: 50%;
  width: 40%;
  height: 100%;
  transform: translateX(-50%);
  margin-top: ${({ marginTop }) => marginTop}px;
  background-color: ${({ color }) => color};
  opacity: ${({ marginTop }) => (marginTop ? 1 : 0)};
`;

const Image = styled.img`
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

export default Image;
