import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useDemoData } from '../../hooks';

export const connect = WrappedComponent => props => {
  const [currentData, setCurrentData] = useState({});
  const demoData = useDemoData();

  const parseData = data => {
    const parsedData = {
      templateId: data.product_demo_animation_template_uuid,
      logo: data.start_screen_logo_url,
      logoAlt: data.start_screen_logo_alt_txt,
      modelName: data.start_screen_subtitle,
      subtitleColor: data.start_screen_subtitle_text_color,
      subtitleFont: data.start_screen_subtitle_font,
      title: data.start_screen_title,
      titleColor: data.start_screen_title_text_color,
      titleFont: data.start_screen_title_font,
      bgColor: data.start_screen_background_color,
      accentColor: data.start_screen_accent_color,
      featuresTitleColor: data.feature_title_text_color,
      featuresModelNameColor: data.feature_subtitle_text_color,
      featuresDescriptionColor: data.feature_description_text_color,
      features: data.features,
      imagesBaseUrl: data.images_base_url,
    };
    return parsedData;
  };

  useEffect(() => {
    if (!isEmpty(demoData)) setCurrentData(parseData(demoData));
    return () => {};
  }, [demoData]);

  return <WrappedComponent {...currentData} {...props} />;
};

export default connect;
