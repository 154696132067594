import sanitizer from 'string-sanitizer';

export const sanitizeString = str =>
  str
    .trim()
    .split(' ')
    .map(strPiece => sanitizer.sanitize(strPiece))
    .join(' ');

export const normalizeScapedLineBreaks = copy => copy.replace(/\\n/g, '\n');

export default { sanitizeString };
