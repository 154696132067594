import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ScrollContainer from './common/ScrollContainer';
import StartScreen, { StartScreenContainer } from './common/StartScreen';
import { defaultProps, propTypes } from '../ModelDetail/propsFile';
import { ID_TEXTS } from '../../utils/constants';
import {
  createFadeOutColorLine,
  createImageRotationTimeline,
  createShowFeatureTimeline,
  createStartScreenTimeline,
} from './common/timeline';
import Feature, {
  FeatureStack,
  FeatureStackCentered,
  FeatureContainer,
  FeatureItem,
  FeatureBottom,
} from './common/Feature';
import Image, {
  ColorLine,
  ImageBaseContainer,
  ImageContainer,
  ImageController,
  ImageWrapper,
} from './common/Image';
import { DEFAULT_360_IMAGE_SIZE } from '../../utils/image';

const TOTAL_360_IMGS = 36;
const PRELOADING_THRESHOLD = Math.ceil(TOTAL_360_IMGS / 8);

const imageUrls = Array.from({ length: TOTAL_360_IMGS }).map(
  (_, index) =>
    `https://app.irobot.mdsrv.media/${DEFAULT_360_IMAGE_SIZE}/public-assets/product-demo-v2/intros/rcj9plus/RoombaCombo_j9plus_Photo_Studio_360_${
      index + 1
    }.webp`
);

const imageBaseUrl =
  'https://app.irobot.mdsrv.media/public-assets/product-demo-v2/intros/rcj9plus/RoombaCombo_j9plus_CleanBase_Photo_Studio_RightFacing.webp';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default function TemplateComboJ9PlusNoDock({
  bgColor,
  logo,
  logoAlt,
  modelName,
  subtitleFont,
  subtitleColor,
  title,
  titleFont,
  titleColor,
  accentColor,
  featuresTitleColor,
  featuresModelNameColor,
  featuresDescriptionColor,
  features,
}) {
  const [containerHeight, setContainerHeight] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const scrollContainerRef = useRef(null);
  const mainTimeLineRef = useRef(null);
  const scrollTriggerRef = useRef(null);
  const scrollToRef = useRef(null);

  function handleImageLoad(e) {
    setImageHeight(e.target.clientHeight);
    setLoadedImages(counter => counter + 1);
  }

  function handleNext(label, duration = 3) {
    const mainTimeline = mainTimeLineRef.current;
    const scrollTrigger = scrollTriggerRef.current;
    if (mainTimeline && scrollTrigger) {
      const targetLabel = mainTimeline.labels[label];
      if (targetLabel) {
        const percent = targetLabel / mainTimeline.totalDuration();
        const scrollPos =
          scrollTrigger.start +
          (scrollTrigger.end - scrollTrigger.start) * percent;
        if (scrollToRef.current) {
          scrollToRef.current.kill();
        }
        scrollToRef.current = gsap.to(window, {
          duration,
          scrollTo: scrollPos,
          onComplete: () => {
            scrollToRef.current = null;
          },
        });
      }
    }
  }

  function handleEnd() {
    if (scrollToRef.current) {
      scrollToRef.current.kill();
    }
    scrollToRef.current = gsap.to(window, {
      duration: 1,
      scrollTo: `#${ID_TEXTS.MAIN_CONTENT_REF}`,
      onComplete: () => {
        scrollToRef.current = null;
      },
    });
  }

  useEffect(() => {
    function handleWrapperChange() {
      setContainerHeight(document.documentElement.clientHeight);
    }
    handleWrapperChange();

    window.addEventListener('resize', handleWrapperChange);
    window.addEventListener('deviceorientation', handleWrapperChange);

    return () => {
      window.removeEventListener('resize', handleWrapperChange);
      window.removeEventListener('deviceorientation', handleWrapperChange);
    };
  }, []);

  useEffect(() => {
    if (loadedImages >= PRELOADING_THRESHOLD) {
      setLoading(false);
    }
  }, [loadedImages]);

  useEffect(() => {
    const element = scrollContainerRef.current;
    if (!loading && element && imageHeight > 0) {
      const startScreenTimeline = createStartScreenTimeline({ element });
      const createFeatureOneTimeline = () => {
        const fadeOutColorLine = createFadeOutColorLine({ element });
        const showFeature = createShowFeatureTimeline({
          element,
          nextFeature: `#${ID_TEXTS.FEATURE_1}`,
        });
        const rotateImage = createImageRotationTimeline({
          element,
          startFrame: 36,
          endFrame: 19,
          attributes: {
            y: containerHeight / 2 - imageHeight * 1.25,
            x: 180,
            scale: 1.75,
            transformOrigin: 'center top',
          },
          onUpdate: index => setCurrentImageIndex(index),
        });
        return gsap
          .timeline()
          .add(fadeOutColorLine)
          .add(showFeature, 0)
          .add(rotateImage, 0);
      };
      const createFeatureTwoTimeline = () => {
        const fadeOutColorLine = createFadeOutColorLine({ element });
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.FEATURE_1}`,
          nextFeature: `#${ID_TEXTS.FEATURE_2}`,
        });
        const translateImage = gsap
          .timeline()
          .to(element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`), {
            y: 0,
            x: 0,
            scale: 0.75,
            transformOrigin: 'center top',
          });
        return gsap
          .timeline()
          .add(fadeOutColorLine)
          .add(showFeature, 0)
          .add(translateImage, 0);
      };
      const createFeatureFourTimeline = () => {
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.FEATURE_2}`,
          nextFeature: `#${ID_TEXTS.FEATURE_4}`,
        });
        const translateImage = gsap
          .timeline()
          .to(element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`), {
            y: -imageHeight / 3,
            x: 200,
            scale: 2,
            transformOrigin: 'center top',
          });
        return gsap.timeline().add(translateImage).add(showFeature, 0.1);
      };
      const createFeatureFiveTimeline = () => {
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.FEATURE_4}`,
          nextFeature: `#${ID_TEXTS.FEATURE_5}`,
        });
        const rotateImage = createImageRotationTimeline({
          element,
          startFrame: 19,
          endFrame: 1,
          attributes: {
            x: 0,
            y: containerHeight / 1.75,
            scale: (containerHeight / imageHeight) * 1.25,
            transformOrigin: 'center top',
          },
          onUpdate: index => setCurrentImageIndex(index),
        });
        return gsap.timeline().add(rotateImage).add(showFeature, 0);
      };
      const createFeatureSixTimeline = () => {
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.FEATURE_5}`,
          nextFeature: `#${ID_TEXTS.FEATURE_6}`,
        });
        const rotateImage = createImageRotationTimeline({
          element,
          startFrame: 1,
          endFrame: 14,
          attributes: {
            y: containerHeight / 2 - imageHeight,
            scale: 2,
            transformOrigin: 'center top',
          },
          onUpdate: index => setCurrentImageIndex(index),
        });

        return gsap.timeline().add(rotateImage, 0).add(showFeature, 0);
      };

      const mainTimeLine = gsap.timeline();
      mainTimeLine.addLabel('step1');
      mainTimeLine.add(startScreenTimeline, '+=0.5');
      mainTimeLine.addLabel('step2');
      mainTimeLine.add(createFeatureOneTimeline(), '+=0.5');
      mainTimeLine.addLabel('step3');
      mainTimeLine.add(createFeatureTwoTimeline(), '+=0.5');
      mainTimeLine.addLabel('step4');
      mainTimeLine.add(createFeatureFourTimeline(), '+=0.5');
      mainTimeLine.addLabel('step5');
      mainTimeLine.add(createFeatureFiveTimeline(), '+=0.5');
      mainTimeLine.addLabel('step6');
      mainTimeLine.add(createFeatureSixTimeline(), '+=0.5');
      mainTimeLine.set({}, {}, '+=0.5');
      mainTimeLine.addLabel('step7');

      const scrollTrigger = ScrollTrigger.create({
        animation: mainTimeLine,
        trigger: element,
        start: 'top top',
        end: '+=600%',
        scrub: true,
        pin: true,
      });

      mainTimeLineRef.current = mainTimeLine;
      scrollTriggerRef.current = scrollTrigger;
    }
  }, [containerHeight, imageHeight, loading]);

  return (
    <ScrollContainer bgColor={bgColor} ref={scrollContainerRef}>
      {loading || containerHeight === 0 ? <Loader /> : null}
      {containerHeight > 0 ? (
        <StartScreenContainer
          id={ID_TEXTS.INTRO_CONTENT}
          height={Math.ceil(containerHeight * 0.7)}
        >
          <StartScreen
            logo={logo}
            logoAlt={logoAlt}
            model={modelName}
            modelFont={subtitleFont}
            modelColor={subtitleColor}
            title={title}
            titleFont={titleFont}
            titleColor={titleColor}
            accentColor={accentColor}
            height={Math.ceil(containerHeight * 0.7)}
            onNext={() => handleNext('step3', 2)}
          />
        </StartScreenContainer>
      ) : null}
      <ImageContainer id={ID_TEXTS.IMAGES_CONTAINER} height={containerHeight}>
        <ImageController height={Math.ceil(containerHeight * 0.3 * 2)}>
          {imageUrls.map((src, index) => (
            <ImageWrapper key={`j9+-image-${index + 1}`}>
              <Image
                src={src}
                alt={`j9+ 360-image frame ${index + 1}`}
                active={index + 1 === currentImageIndex}
                onLoad={handleImageLoad}
              />
            </ImageWrapper>
          ))}
          <ColorLine
            id={ID_TEXTS.COLOR_LINE}
            color={accentColor}
            marginTop={imageHeight / 2}
          />
        </ImageController>
      </ImageContainer>
      <ImageBaseContainer
        id={ID_TEXTS.IMAGE_BASE_CONTAINER}
        height={containerHeight}
      >
        <ImageController height={Math.ceil(containerHeight * 0.3 * 2)}>
          <ImageWrapper>
            <Image
              src={imageBaseUrl}
              alt="Roomba Combo j9 Series Base"
              active
            />
          </ImageWrapper>
        </ImageController>
      </ImageBaseContainer>
      <FeatureContainer>
        <FeatureStack id={ID_TEXTS.FEATURE_1}>
          <FeatureItem />
          <FeatureItem>
            <FeatureBottom
              icon={features[0]?.icon_url}
              iconAlt={features[0]?.icon_alt_txt}
              model={features[0]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[0]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[0]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[0]?.legal_footnote}
              onNext={() => handleNext('step4', 1)}
              overlay
            />
          </FeatureItem>
        </FeatureStack>
        <FeatureStack id={ID_TEXTS.FEATURE_2}>
          <FeatureItem />
          <FeatureItem>
            <FeatureBottom
              icon={features[1]?.icon_url}
              iconAlt={features[1]?.icon_alt_txt}
              model={features[1]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[1]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[1]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[1]?.legal_footnote}
              onNext={() => handleNext('step5', 3)}
            />
          </FeatureItem>
        </FeatureStack>
        <FeatureStackCentered id={ID_TEXTS.FEATURE_4}>
          <Feature
            icon={features[2]?.icon_url}
            iconAlt={features[2]?.icon_alt_txt}
            model={features[2]?.subtitle}
            modelColor={featuresModelNameColor}
            modelFont={subtitleFont}
            title={features[2]?.title}
            titleColor={featuresTitleColor}
            titleFont={titleFont}
            description={features[2]?.description}
            descriptionColor={featuresDescriptionColor}
            accentColor={accentColor}
            footnote={features[2]?.legal_footnote}
            overlay
            onNext={() => handleNext('step6', 1.5)}
          />
        </FeatureStackCentered>
        <FeatureStack id={ID_TEXTS.FEATURE_5}>
          <Feature
            icon={features[3]?.icon_url}
            iconAlt={features[3]?.icon_alt_txt}
            model={features[3]?.subtitle}
            modelColor={featuresModelNameColor}
            modelFont={subtitleFont}
            title={features[3]?.title}
            titleColor={featuresTitleColor}
            titleFont={titleFont}
            description={features[3]?.description}
            descriptionColor={featuresDescriptionColor}
            accentColor={accentColor}
            footnote={features[3]?.legal_footnote}
            onNext={() => handleNext('step7', 2)}
          />
        </FeatureStack>
        <FeatureStackCentered id={ID_TEXTS.FEATURE_6}>
          <Feature
            icon={features[4]?.icon_url}
            iconAlt={features[4]?.icon_alt_txt}
            model={features[4]?.subtitle}
            modelColor={featuresModelNameColor}
            modelFont={subtitleFont}
            title={features[4]?.title}
            titleColor={featuresTitleColor}
            titleFont={titleFont}
            description={features[4]?.description}
            descriptionColor={featuresDescriptionColor}
            accentColor={accentColor}
            footnote={features[4]?.legal_footnote}
            overlay
            onNext={() => handleEnd()}
          />
        </FeatureStackCentered>
      </FeatureContainer>
    </ScrollContainer>
  );
}

TemplateComboJ9PlusNoDock.propTypes = propTypes;
TemplateComboJ9PlusNoDock.defaultProps = defaultProps;

const Loader = styled.div``;
