import React, { useState, useEffect } from 'react';
import { useDemoData } from '../../hooks';
import ViewAllProductModel from '../../models/ViewAllProductsModel';

const parseViewAllProductsData = demoData => {
  const data = new ViewAllProductModel(demoData || {}).props;
  return data;
};

export const connect = WrappedComponent => props => {
  const [currentViewAllData, setCurrentViewAllData] = useState({});
  const demoData = useDemoData();
  useEffect(() => {
    if (demoData) setCurrentViewAllData(parseViewAllProductsData(demoData));
    return () => {};
  }, [demoData]);
  return <WrappedComponent {...currentViewAllData} {...props} />;
};

export default connect;
