import React, { useState, useEffect } from 'react';
// Used to generate JSON file of mock data
// import demoMockData from '../../data';
import {
  fetchExperienceMock,
  fetchData as fetchProductDemoData,
} from '../../services/client';
import analytics from '../../analytics';

export const connect = WrappedComponent => props => {
  // Used to generate JSON file of mock data
  // console.log(JSON.stringify(demoMockData));
  const [currentDemoData, setCurrentDemoData] = useState({});
  useEffect(() => {
    // Load data from server
    // eslint-disable-next-line no-unused-vars
    async function getDemoData() {
      try {
        const { apiUrl, experienceId, showViewAll } = document.getElementById(
          'irobot-embedded-product-demo'
        ).dataset;

        const { data: fetchedData } = await fetchProductDemoData(
          apiUrl,
          experienceId
        );

        if (fetchedData) {
          setCurrentDemoData({ ...fetchedData, showViewAll: !!showViewAll });
          // Push load demo event
          analytics.trackDemoLoad(
            showViewAll ? 'In-Store' : 'Website',
            fetchedData?.productName || 'n/a'
          );
        }
      } catch (error) {
        console.error('Error fetching product demo data', error);
      }
    }

    // Load mock response
    // eslint-disable-next-line no-unused-vars
    async function getDemoMockData() {
      try {
        const { showViewAll } = document.getElementById(
          'irobot-embedded-product-demo'
        ).dataset;

        const response = await fetchExperienceMock(
          '',
          '39f7d51c-cfda-43be-9608-40ed953a4024'
        );
        setCurrentDemoData({ ...response?.data, showViewAll: !!showViewAll });
      } catch (error) {
        console.error("Error loading product demo's data:", error);
      }
    }

    // Comment out to use mock data instead
    // getDemoMockData();

    // Comment to use use mock data instead
    getDemoData();
    return () => {};
  }, []);
  return <WrappedComponent demoData={currentDemoData} {...props} />;
};

export default connect;
