import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontVariables } from '../../../styles/variables';
import { CarouselNavItemType } from '../../../types';
import analytics from '../../../analytics';

const CarouselNavigation = ({
  navItems,
  navigateTo,
  bgColor,
  textColor,
  itemBgColor,
  currentActiveIndex,
  demoSource,
  productName,
}) => {
  const [trackedCategories, setTrackedCategories] = useState([]);
  const [trackedCategory, setTrackedCategory] = useState('');

  useEffect(() => {
    if (trackedCategory) {
      analytics.trackCategoryShow(trackedCategory, demoSource, productName);
      setTrackedCategory('');
    }
    return () => {};
  }, [demoSource, productName, trackedCategory]);

  return (
    navItems.length > 0 && (
      <NavContainer>
        <NavList bgColor={bgColor}>
          {navItems.map(navItem => {
            const { uuid, title, icon, targetIndex, endIndex } = navItem;
            const isActive =
              targetIndex <= currentActiveIndex &&
              currentActiveIndex <= endIndex;
            if (isActive && !trackedCategories.includes(title)) {
              setTrackedCategory(title);
              setTrackedCategories([...trackedCategories, title]);
            }
            return (
              <NavItem isActive={isActive} bgColor={itemBgColor} key={uuid}>
                <button
                  type="button"
                  onClick={() => {
                    navigateTo(targetIndex);
                  }}
                >
                  <NavItemLabel isActive={isActive} textColor={textColor}>
                    {title}
                  </NavItemLabel>
                  <NavItemIconWrapper bgColor={itemBgColor} isActive={isActive}>
                    <NavItemIcon src={icon} alt={title} />
                  </NavItemIconWrapper>
                </button>
              </NavItem>
            );
          })}
        </NavList>
      </NavContainer>
    )
  );
};

const NavContainer = styled.nav`
  text-align: center;
`;

const NavList = styled.ul`
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(43)
      : theme.webRT.getWidthValue(55)}px;
  padding: 0
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(8)
        : theme.webRT.getWidthValue(16)}px;
  margin: 0;
  min-width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(0)
      : theme.webRT.getWidthValue(417)}px;
  display: inline-flex;
  list-style: none;
  border-radius: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(22)
      : theme.webRT.getWidthValue(22)}px;
  box-shadow: 0
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(3)
        : theme.webRT.getFontSize(3)}px
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(6)
        : theme.webRT.getWidthValue(6)}px
    rgba(0, 0, 0, 0.16);
  justify-content: space-between;
`;

const NavItem = styled.li`
  position: relative;
  padding: 0
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(24)
        : theme.webRT.getWidthValue(24)}px;
  button {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    height: 100%;
    cursor: pointer;
  }
`;

const NavItemLabel = styled.span`
  text-transform: uppercase;
  color: ${({ textColor }) => textColor};
  font-family: ${fontVariables.families.primaryFont};
  font-weight: ${fontVariables.weights.bold};
  font-size: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getFontSize(14)
      : theme.webRT.getFontSize(17)}px;
  line-height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(14)
      : theme.webRT.getWidthValue(23)}px;
  letter-spacing: 0.17px;
  z-index: 1;
  opacity: ${({ isActive }) => (!isActive ? '1' : '0')};
  transition: opacity 0.85s linear;
`;

const NavItemIconWrapper = styled.div`
  box-sizing: border-box;
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(44)
      : theme.webRT.getWidthValue(56)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(51)
      : theme.webRT.getWidthValue(64)}px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(21)
      : theme.webRT.getWidthValue(28)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(6)
      : theme.webRT.getWidthValue(8)}px;
  left: 50%;
  z-index: 2;
  transform-origin: 0 50%;
  transform: ${({ isActive }) =>
    isActive ? `translateX(-50%) scale(1)` : `scale(0.4) translateX(-50%)`};
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: transform 0.85s linear, opacity 0.85s linear;
`;

const NavItemIcon = styled.img`
  display: block;
  width: auto;
  height: 50%;
`;

CarouselNavigation.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape(CarouselNavItemType)),
  navigateTo: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  itemBgColor: PropTypes.string,
  currentActiveIndex: PropTypes.number,
  demoSource: PropTypes.string,
  productName: PropTypes.string,
};

CarouselNavigation.defaultProps = {
  navItems: [],
  navigateTo: () => {},
  bgColor: '',
  textColor: '',
  itemBgColor: '',
  currentActiveIndex: 0,
  demoSource: '',
  productName: '',
};

export default CarouselNavigation;
