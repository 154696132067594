import ga from './ga';
import { sanitizeString } from '../utils/string';
import pixel from './pixel';

const { dataLayer } = window;
let genomeTP = null;

export const trackDemoLoad = (demoSource = '', productName = '') => {
  try {
    ga.onDemoLoadGA(dataLayer, demoSource, sanitizeString(productName));
    genomeTP = window.genomeTP;
    pixel.onDemoLoad(genomeTP, demoSource, sanitizeString(productName));
  } catch (error) {
    console.error(error);
  }
};

export const trackButtonClick = (buttonName = '', productName = '') => {
  try {
    ga.onButtonClick(
      dataLayer,
      sanitizeString(buttonName),
      sanitizeString(productName)
    );
    pixel.onButtonClick(
      genomeTP,
      sanitizeString(buttonName),
      sanitizeString(productName)
    );
  } catch (error) {
    console.error(error);
  }
};

export const trackCategoryShow = (
  activeCategory = '',
  demoSource = '',
  productName = ''
) => {
  try {
    ga.onCategoryShow(dataLayer, activeCategory, demoSource, productName);
    pixel.onCategoryShow(genomeTP, activeCategory, demoSource, productName);
  } catch (error) {
    console.error(error);
  }
};

const analytics = {
  trackButtonClick,
  trackDemoLoad,
  trackCategoryShow,
};

export default analytics;
