export const ID_TEXTS = {
  MAIN_CONTENT: 'main-content',
  MAIN_CONTENT_REF: 'main-content-ref',
  MODEL_DETAIL_WRAPPER: 'model-detail-wrapper',
  IMAGES_CONTAINER: 'model-images-container',
  IMAGES_WRAPPER: 'model-images-wrapper',
  IMAGE_BASE_CONTAINER: 'model-image-base-container',
  COLOR_LINE: 'model-color-line',
  FEATURE_1: 'model-feature-1',
  FEATURE_2: 'model-feature-2',
  FEATURE_3: 'model-feature-3',
  FEATURE_4: 'model-feature-4',
  FEATURE_5: 'model-feature-5',
  FEATURE_6: 'model-feature-6',
  INTRO_LOGO: 'intro-logo',
  INTRO_CONTENT: 'intro-content',
  START_CONTENT: 'start-content',
  FEATURE_DOWN_ARROW: 'feature-down-arrow',
  SLIDE_1: 'slide-1',
  SLIDE_2: 'slide02',
  IMAGE_FADE_RIGHT_1: 'image_fade_right_1',
  IMAGE_FADE_RIGHT_2: 'image_fade_right_2',
};

export const TOTAL_360_IMGS = 36;
export const PRELOADING_THRESHOLD = Math.ceil(TOTAL_360_IMGS / 8);

export default { ID_TEXTS };
