import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import useResponsiveTools from '../../hooks/useResponsiveTools';
import { DemoContext } from '../../context';

function App({ children, demoData }) {
  const responsiveUtilities = useResponsiveTools();

  return (
    <ThemeProvider theme={responsiveUtilities}>
      <DemoContext.Provider value={demoData}>
        <div id="irobot-product-demo-v2">{children}</div>
      </DemoContext.Provider>
    </ThemeProvider>
  );
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  demoData: PropTypes.object,
};

App.defaultProps = {
  demoData: {},
};

export default App;
