import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { propTypes, defaultProps } from '../propsFile';
import Intro from '../Intro';
import Feature from '../Feature';
import Loader from '../Loader';
import useResponsiveTools from '../../../hooks/useResponsiveTools';
import { ID_TEXTS } from '../../../utils/constants';
import { DEFAULT_360_IMAGE_SIZE } from '../../../utils/image';

const TOTAL_360_IMGS = 36;
const PRELOADING_THRESHOLD = Math.ceil(TOTAL_360_IMGS / 8);

const urlsArr = [...Array(TOTAL_360_IMGS)].map(
  (url, index) =>
    `https://app.irobot.mdsrv.media/${DEFAULT_360_IMAGE_SIZE}/public-assets/product-demo-v2/intros/I3/1280/webp/i3_Neutral_360Images_${
      index + 1
    }.webp`
);

const I5Detail = ({
  logo,
  logoAlt,
  modelName,
  subtitleColor,
  subtitleFont,
  title,
  titleColor,
  titleFont,
  bgColor,
  accentColor,
  featuresTitleColor,
  featuresModelNameColor,
  featuresDescriptionColor,
  features,
}) => {
  gsap.registerPlugin(ScrollTrigger);

  const responsiveUtilities = useResponsiveTools();
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [loadedImages, setLoadedImages] = useState(0);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef();

  const renderImages = useCallback(
    imgsArr =>
      imgsArr.map((src, index) => (
        <StyledImage
          key={`i5-image-${index + 1}`}
          src={src}
          alt={`i5 image ${index + 1}`}
          active={index + 1 === currentImageIndex}
          onLoad={() => {
            setLoadedImages(l => l + 1);
          }}
        />
      )),
    [currentImageIndex]
  );

  useEffect(() => {
    const { isMobile, mobileRT, webRT } = responsiveUtilities;
    const element = containerRef.current;

    if (!loading && element && features) {
      const scene2RotationFrames = {
        frame: 1,
      };

      const scene4RotationFrames1 = {
        frame: 14,
      };

      const scene4RotationFrames2 = {
        frame: 36,
      };

      const mainTimeLine = gsap.timeline();

      const imageRotation = frame => {
        setCurrentImageIndex(Math.round(frame));
      };

      const scene1Timeline = () => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`), {
          y: isMobile
            ? mobileRT.getWidthValue(-360)
            : webRT.getWidthValue(-530),
        });
        tl.to(
          element.querySelector(`#${ID_TEXTS.INTRO_CONTENT}`),
          {
            opacity: 0,
          },
          0
        );
        tl.delay(0.5);

        return tl;
      };

      const scene2Timeline = () => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(`#${ID_TEXTS.INTRO_LOGO}`), {
          y: isMobile ? mobileRT.getWidthValue(-70) : webRT.getWidthValue(-150),
          onStart: () => {
            element.querySelector(
              `#${ID_TEXTS.INTRO_CONTENT}`
            ).style.visibility = 'hidden';
          },
          onReverseComplete: () => {
            element.querySelector(
              `#${ID_TEXTS.INTRO_CONTENT}`
            ).style.visibility = 'visible';
          },
        });
        tl.to(
          element.querySelector(`#${ID_TEXTS.COLOR_LINE}`),
          {
            opacity: 0,
          },
          0
        );
        tl.to(
          element.querySelector(`#${ID_TEXTS.FEATURE_1}`),
          {
            opacity: 1,
          },
          0
        );
        tl.from(
          element.querySelector(`#${ID_TEXTS.FEATURE_DOWN_ARROW}`),
          {
            y: isMobile ? mobileRT.getWidthValue(30) : webRT.getWidthValue(30),
          },
          0
        );
        tl.to(
          element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`),
          {
            x: isMobile ? mobileRT.getWidthValue(30) : webRT.getWidthValue(120),
            ease: 'power4.in',
          },
          0
        );
        tl.to(
          element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`),
          {
            y: isMobile
              ? mobileRT.getWidthValue(110)
              : webRT.getWidthValue(300),
            scale: isMobile ? 2.3 : 2.4,
          },
          0
        );
        tl.to(
          scene2RotationFrames,
          {
            frame: 14,
            ease: 'power2.in',
            onUpdate: () => imageRotation(scene2RotationFrames.frame),
          },
          0
        );

        return tl;
      };

      const textAnimations = (element1, element2, yValue1, yValue2) => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(element1), {
          opacity: 0,
          y: isMobile
            ? mobileRT.getWidthValue(yValue1)
            : webRT.getWidthValue(yValue1),
        });
        tl.to(element.querySelector(element2), {
          opacity: 1,
          y: isMobile
            ? mobileRT.getWidthValue(yValue2)
            : webRT.getWidthValue(yValue2),
        });

        return tl;
      };

      const scene3ImageScaleAnimation = () => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`), {
          x: isMobile ? mobileRT.getWidthValue(90) : webRT.getWidthValue(10),
          y: isMobile ? mobileRT.getWidthValue(60) : webRT.getWidthValue(200),
          scale: 2,
        });
        tl.timeScale(0.5);
        return tl;
      };

      const scene3ImageRotationAnimation = () => {
        const tl = gsap.timeline();

        tl.to(scene4RotationFrames1, {
          frame: 1,
          onUpdate: () => imageRotation(scene4RotationFrames1.frame),
        });
        tl.to(scene4RotationFrames2, {
          frame: 19,
          onUpdate: () => imageRotation(scene4RotationFrames2.frame),
        });
        return tl;
      };

      const scene3ImageAnimations = () => {
        const tl = gsap.timeline();

        tl.add(scene3ImageScaleAnimation());
        tl.add(scene3ImageRotationAnimation(), 0);

        return tl;
      };

      const scene3Timeline = () => {
        const tl = gsap.timeline();

        tl.add(scene3ImageAnimations());
        tl.add(
          textAnimations(
            `#${ID_TEXTS.FEATURE_1}`,
            `#${ID_TEXTS.FEATURE_2}`,
            -400,
            -100
          ),
          0
        );

        return tl;
      };

      mainTimeLine.add(scene1Timeline());
      mainTimeLine.add(scene2Timeline());
      mainTimeLine.add(scene3Timeline(), '+=0.5');

      ScrollTrigger.create({
        animation: mainTimeLine,
        trigger: element,
        start: 'top top',
        end: '+=300%',
        scrub: true,
        pin: true,
      });
    }
  }, [loading, responsiveUtilities, features]);

  useEffect(() => {
    if (loadedImages >= PRELOADING_THRESHOLD && loading) {
      setLoading(false);
    }
    return () => {};
  }, [loadedImages, loading]);

  return (
    <>
      <LoaderWrapper $loading={loading}>
        <Loader bgColor={bgColor} color={accentColor} />
      </LoaderWrapper>
      <Container $loading={loading} ref={containerRef} bgColor={bgColor}>
        {logo && (
          <Intro
            logo={logo}
            logoAlt={logoAlt}
            model={modelName}
            modelColor={subtitleColor}
            modelFont={subtitleFont}
            title={title}
            titleColor={titleColor}
            titleFont={titleFont}
            bgColor={bgColor}
            accentColor={accentColor}
          />
        )}
        <ImagesContainer id={ID_TEXTS.IMAGES_CONTAINER}>
          <ImagesWrapper id={ID_TEXTS.IMAGES_WRAPPER}>
            {renderImages(urlsArr)}
          </ImagesWrapper>
          <ColorLine id={ID_TEXTS.COLOR_LINE} color={accentColor} />
        </ImagesContainer>
        {features && !!features.length && (
          <>
            <StyledFeature
              id={ID_TEXTS.FEATURE_1}
              icon={features[0]?.icon_url}
              iconAlt={features[0]?.icon_alt_txt}
              model={features[0]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[0]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[0]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[0]?.legal_footnote}
            />
            <AltStyledFeature
              id={ID_TEXTS.FEATURE_2}
              icon={features[1]?.icon_url}
              iconAlt={features[1]?.icon_alt_txt}
              model={features[1]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[1]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[1]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[1]?.legal_footnote}
            />
          </>
        )}
      </Container>
    </>
  );
};

const LoaderWrapper = styled.div`
  display: ${({ $loading }) => ($loading ? 'block' : 'none')};
`;

const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: ${({ $loading }) => ($loading ? 'none' : 'block')};
`;

const ImagesContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => (theme.isMobile ? '100%' : '40%')};
  left: ${({ theme }) => (theme.isMobile ? '0' : '50%')};
  transform: ${({ theme }) => (theme.isMobile ? 'none' : 'translate(-50%, 0)')};
  height: ${({ theme }) =>
    theme.isMobile
      ? `calc(100vh - ${theme.mobileRT.getWidthValue(40)}px)`
      : `calc(100vh - ${theme.mobileRT.getWidthValue(10)}px)`};
  top: ${({ theme }) =>
    theme.isMobile ? 'auto' : `${theme.webRT.getWidthValue(630)}px`};
`;

const ColorLine = styled.div`
  background-color: ${({ color }) => color};
  flex: 1;
  z-index: 1;
  margin-top: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(-85)
      : theme.webRT.getWidthValue(-200)}px;
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(166)
      : theme.webRT.getWidthValue(396)}px;
`;

const StyledFeature = styled(Feature)`
  position: absolute;
  opacity: 0;
  top: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(156)
      : theme.webRT.getWidthValue(240)}px;
  left: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(35)
      : theme.webRT.getWidthValue(169)}px;
`;

const AltStyledFeature = styled(StyledFeature)`
  bottom: ${({ theme }) =>
    theme.isMobile
      ? `${theme.mobileRT.getWidthValue(-50)}px`
      : `${theme.webRT.getWidthValue(0)}px`};
`;

const ImagesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
  padding-bottom: 100%;
`;

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

I5Detail.propTypes = propTypes;

I5Detail.defaultProps = defaultProps;

export default I5Detail;
