import CATEGORIES from './slideCategories';

export const SLIDE_CATEGORIES = CATEGORIES;
export const ACTIVE_MODEL_INDEX = 1;
export const MODEL_NAMES = [
  'S9',
  'J7',
  'I7',
  'I3',
  'I1',
  'M6',
  'I4',
  'I5',
  'J7_POOP',
  'I3_NO_PMAPS',
];

export default { SLIDE_CATEGORIES, ACTIVE_MODEL_INDEX };
