import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Title from '../GeniusCarouselTitle/GeniusCarouselTitle';
import Slide from '../Slide';
import CarouselNavigation from '../CarouselNavigation';
import useResponsiveTools from '../../../hooks/useResponsiveTools';
import { useOnVisible } from '../../../hooks';
import { CarouselNavItemType, SlideType } from '../../../types';
import { colors } from '../../../styles/variables';

const preloadMedia = mediaUrlsArr => {
  mediaUrlsArr.map(url => {
    const newImg = new Image();
    newImg.src = url;
    return newImg;
  });
};

const GeniusCarouselWrapper = ({
  title,
  titleColor,
  titleFont,
  bgColor,
  bgImage,
  bgImageMb,
  slides,
  navBgColor,
  navSelectedColor,
  navTextColor,
  navItems,
  mediaUrls,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [prevSlideIndex, setPrevSlideIndex] = useState(-1);
  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);

  const responsiveUtilities = useResponsiveTools();
  const { isMobile, webRT } = responsiveUtilities;

  const isSliderVisible = useOnVisible({
    ref: sliderWrapperRef,
    options: { threshold: 0.35 },
  });

  const sliderDesktopSettings = {
    speed: 1850,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
    centerPadding: `${webRT.getWidthValue(200)}px`,
    variableWidth: true,
    cssEase: 'cubic-bezier(.26,1.39,.42,.97)',
    beforeChange: (current, next) => {
      setCurrentSlideIndex(next);
      setPrevSlideIndex(next - 1);
    },
    afterChange: current => {
      setActiveSlideIndex(current);
    },
  };

  const sliderMobileSettings = {
    speed: 850,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    beforeChange: (current, next) => {
      setCurrentSlideIndex(next);
      if (current < next) {
        setPrevSlideIndex(current);
      } else {
        setPrevSlideIndex(next - 1);
      }
    },
    afterChange: current => {
      setActiveSlideIndex(current);
    },
  };

  const sliderSettings = isMobile
    ? sliderMobileSettings
    : sliderDesktopSettings;

  useEffect(() => {
    if (mediaUrls && mediaUrls.length > 0) {
      window.preloadedMedia = preloadMedia(mediaUrls);
    }
    return () => {};
  }, [mediaUrls]);
  return (
    <Container bgColor={bgColor} bgImage={bgImage}>
      {!isMobile && (
        <Title
          title={title}
          bgColor={bgColor}
          titleColor={titleColor}
          titleFont={titleFont}
          bgImageMb={bgImageMb}
        />
      )}
      <SliderWrapper ref={sliderWrapperRef}>
        <Slider {...sliderSettings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <Slide
              // eslint-disable-next-line react/no-array-index-key
              key={slide.uuid}
              title={slide.title}
              subtitle={slide.subtitle}
              description={slide.description}
              footNote={slide.footNote}
              bgColor={bgColor}
              mediaFormat={slide.mediaFormat}
              mediaUrl={slide.mediaUrl}
              altText={slide.altText}
              thumbUrl={slide.thumbUrl}
              duration={`${slide.duration * 1000}`}
              groupSize={slide.groupSize}
              groupIndex={slide.groupIndex}
              slideIndex={index}
              prevIndex={prevSlideIndex}
              currentIndex={currentSlideIndex}
              isOpen={currentSlideIndex === index}
              isActive={activeSlideIndex === index}
              isVisible={isSliderVisible}
            />
          ))}
        </Slider>
        <CarouselNavigationWrapper>
          <CarouselNavigation
            navigateTo={sliderRef?.current?.slickGoTo}
            currentActiveIndex={currentSlideIndex}
            bgColor={navBgColor}
            itemBgColor={navSelectedColor}
            textColor={navTextColor}
            navItems={navItems}
          />
        </CarouselNavigationWrapper>
      </SliderWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  ${({ bgImage }) => bgImage && `background-image: url(${bgImage});`}
  padding-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(30)
      : theme.webRT.getWidthValue(30)}px;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: ${props => props.theme.webRT.getWidthValue(1234)}px
    ${props => props.theme.webRT.getWidthValue(617)}px;
  &,
  & * {
    min-height: 0px;
    min-width: 0px;
    max-width: none;
  }
`;

const SliderWrapper = styled.div`
  padding: 0;
  position: relative;
  .slick-list {
    height: ${({ theme }) =>
      theme.isMobile ? 'auto' : `${theme.webRT.getWidthValue(795)}px`};
  }

  .slick-list {
    width: auto;
  }

  .slick-slide {
    width: auto;
  }
  &&& {
    .slick-prev,
    .slick-next {
      opacity: 1;
      height: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(59)
          : theme.webRT.getWidthValue(59)}px;
      width: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(59)
          : theme.webRT.getWidthValue(59)}px;
      border-radius: 50%;
      z-index: 99;

      background-image: url('https://app.irobot.mdsrv.media/public-assets/product-demo-v2/images/sliderArrow@2x.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-color: ${colors.grey1};
      background-size: ${({ theme }) =>
          theme.isMobile
            ? theme.mobileRT.getWidthValue(16)
            : theme.webRT.getWidthValue(16)}px
        ${({ theme }) =>
          theme.isMobile
            ? theme.mobileRT.getWidthValue(16)
            : theme.webRT.getWidthValue(16)}px;
      transition: opacity 0.15s linear;
      &:before {
        content: none;
      }

      &:hover {
        opacity: 0.9;
      }
      &:active {
        transition: none;
        opacity: 0.75;
      }
      &.slick-disabled {
        opacity: 0;
        &:hover {
          cursor: default;
        }
      }
    }
    .slick-prev {
      left: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(360)
          : theme.webRT.getWidthValue(360)}px;
      transform: rotate(180deg) translate3d(0, 100%, 0);
    }
    .slick-next {
      right: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(420)
          : theme.webRT.getWidthValue(420)}px;
      transform: rotate(0) translate3d(0, -100%, 0);
    }
  }
  .slick-slide.slick-active.slick-center.slick-current {
    margin-right: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(64)
        : theme.webRT.getWidthValue(64)}px;
  }
`;

const CarouselNavigationWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(-10)
      : theme.webRT.getWidthValue(84)}px;
  left: 0;
  width: 100%;
`;

GeniusCarouselWrapper.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  bgImageMb: PropTypes.string,
  navBgColor: PropTypes.string,
  navSelectedColor: PropTypes.string,
  navTextColor: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape(SlideType)),
  navItems: PropTypes.arrayOf(PropTypes.shape(CarouselNavItemType)),
  mediaUrls: PropTypes.arrayOf(PropTypes.string),
};

GeniusCarouselWrapper.defaultProps = {
  title: '',
  titleColor: '',
  titleFont: '',
  bgColor: '',
  bgImage: '',
  bgImageMb: '',
  navBgColor: '',
  navSelectedColor: '',
  navTextColor: '',
  slides: [],
  navItems: [],
  mediaUrls: [],
};

export default GeniusCarouselWrapper;
