import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const MEDIA_URL_REGEX =
  /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)(.+)/;

export function extractUrlMetadata(url) {
  const match = url.match(MEDIA_URL_REGEX);
  const domain = match?.[1];
  const path = match?.[2];
  return {
    domain,
    path,
  };
}

export default function Image({
  src,
  alt,
  className,
  responsiveWidth,
  ...rest
}) {
  const responsiveSrc = useMemo(() => {
    let newSrc = src;
    if (src && responsiveWidth) {
      const { domain, path } = extractUrlMetadata(src);
      if (domain && path) {
        newSrc = `https://${domain}/cdn-cgi/image/width=${responsiveWidth},quality=75,format=webp${path}`;
      }
    }
    return newSrc;
  }, [responsiveWidth, src]);
  return <img src={responsiveSrc} alt={alt} className={className} {...rest} />;
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  responsiveWidth: PropTypes.number,
};

Image.defaultProps = {
  src: '',
  alt: '',
  className: '',
  responsiveWidth: 0,
};
