import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as LoaderIcon } from '../../../assets/svgs/loader.svg';
import { colors } from '../../../styles/variables';

const Loader = ({ bgColor, color }) => (
  <LoaderWrapper bgColor={bgColor}>
    <LoaderSpinner color={color} />
  </LoaderWrapper>
);

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const LoaderWrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${colors.white};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderSpinner = styled(LoaderIcon)`
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(170)
      : theme.webRT.getWidthValue(170)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(170)
      : theme.webRT.getWidthValue(170)}px;
  animation: ${rotationAnimation} 1.5s infinite linear;

  .alt-color {
    stroke: ${({ color }) => color};
  }
`;

Loader.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

Loader.defaultProps = {
  bgColor: '',
  color: '',
};

export default Loader;
