import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SlideContent from '../SlideContent';
import SlideMedia from '../SlideMedia';
import SlideVideo from '../SlideMedia/SlideVideo';
import { SlideType } from '../../../types';

const Slide = ({
  title,
  subtitle,
  description,
  mediaFormat,
  mediaUrl,
  altText,
  duration,
  footNote,
  thumbUrl,
  isOpen,
  isActive,
  slideIndex,
  currentIndex,
  prevIndex,
  groupSize,
  groupIndex,
  bgColor,
  isVisible,
}) => {
  const [isPrevSlide, setIsPrevSlide] = useState(false);

  useEffect(() => {
    if (prevIndex === slideIndex) {
      setIsPrevSlide(true);
    } else {
      setIsPrevSlide(false);
    }
    return () => {};
  }, [slideIndex, prevIndex]);
  return (
    <Container
      className={`genius-carousel__slide ${
        isPrevSlide ? 'genius-carousel__slide--prev' : ''
      }`}
    >
      <Inner>
        <SliderCarouselWrapper>
          {mediaFormat === 'gif' ? (
            <SlideMedia
              mediaUrl={mediaUrl}
              altText={altText}
              duration={duration}
              thumbUrl={thumbUrl}
              groupIndex={groupIndex}
              groupSize={groupSize}
              slideIndex={slideIndex}
              currentSlideIndex={currentIndex}
              isActive={isActive}
              isVisible={isVisible}
              isCurrent={slideIndex === currentIndex}
            />
          ) : (
            <SlideVideo
              mediaUrl={mediaUrl}
              altText={altText}
              duration={duration}
              thumbUrl={thumbUrl}
              groupIndex={groupIndex}
              groupSize={groupSize}
              slideIndex={slideIndex}
              currentSlideIndex={currentIndex}
              isActive={isActive}
              isVisible={isVisible}
              isCurrent={slideIndex === currentIndex}
            />
          )}
        </SliderCarouselWrapper>
        <SlideContent
          title={title}
          subtitle={subtitle}
          description={description}
          footNote={footNote}
          isOpen={isOpen}
          bgColor={bgColor}
        />
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

const Inner = styled.div`
  position: relative;
  display: ${({ theme }) => (theme.isMobile ? 'block' : 'flex')};
`;

const SliderCarouselWrapper = styled.div`
  width: ${({ theme: { isMobile, webRT } }) =>
    isMobile ? `100%` : `${webRT.getWidthValue(566)}px`};
  height: 100%;
  border-radius: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(0)
      : theme.webRT.getWidthValue(19)}px;
  overflow: hidden;
  height: ${({ theme: { isMobile, webRT } }) =>
    isMobile ? '100%' : webRT.getWidthValue(687)}px;
  z-index: 2;
  transition: transform 0.55s ease-in-out;

  .genius-carousel__slide--prev & {
    transform: ${({ theme }) => (theme.isMobile ? 'none' : 'translateX(80%)')};
  }
`;

Slide.propTypes = SlideType;

Slide.defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  footNote: '',
  mediaFormat: 'gif',
  mediaUrl: '',
  altText: '',
  duration: '',
  thumbUrl: '',
  isOpen: false,
  isActive: false,
  prevIndex: -1,
  currentIndex: 0,
  groupIndex: 0,
  groupSize: 0,
  bgColor: '',
  isVisible: false,
};

export default Slide;
