export const size = {
  mobile: '375px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1440px',
  large: '1680px',
  xlarge: '2160px',
};

const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  large: `(min-width: ${size.large})`,
  xlarge: `(min-width: ${size.xlarge})`,
};

export default device;
