import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ID_TEXTS } from '../../../utils/constants';
import { normalizeScapedLineBreaks } from '../../../utils/string';
import {
  IntroContainer,
  IntroLogoWrapper,
  IntroLogo,
  IntroModelName,
  IntroTitle,
  IntroDownArrowWrapper,
  IntroDownButton,
  IntroDownArrow,
} from '../Intro';

const Intro = ({
  className,
  logo,
  logoAlt,
  model,
  modelColor,
  modelFont,
  title,
  titleColor,
  titleFont,
  bgColor,
  accentColor,
  narrowTitle,
  onNext,
}) => {
  const isTitleLong = title?.length > 45;

  return (
    <IntroContainer
      className={className}
      bgColor={bgColor}
      isTitleLong={isTitleLong}
    >
      <IntroLogoWrapper>
        <IntroLogo src={logo} alt={logoAlt} id={ID_TEXTS.INTRO_LOGO} />
      </IntroLogoWrapper>
      <div id={ID_TEXTS.INTRO_CONTENT}>
        <IntroModelName color={modelColor} font={modelFont}>
          {model}
        </IntroModelName>
        <M6Title
          color={titleColor}
          narrowTitle={narrowTitle}
          isTitleLong={isTitleLong}
          font={titleFont}
        >
          {normalizeScapedLineBreaks(title)}
        </M6Title>
        <IntroDownArrowWrapper>
          {onNext ? (
            <IntroDownButton onClick={onNext}>
              <IntroDownArrow color={accentColor} />
            </IntroDownButton>
          ) : (
            <IntroDownArrow color={accentColor} />
          )}
        </IntroDownArrowWrapper>
      </div>
    </IntroContainer>
  );
};

Intro.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  model: PropTypes.string,
  modelColor: PropTypes.string,
  modelFont: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  bgColor: PropTypes.string,
  accentColor: PropTypes.string,
  narrowTitle: PropTypes.bool,
  onNext: PropTypes.func,
};

Intro.defaultProps = {
  className: '',
  logo: '',
  logoAlt: '',
  model: '',
  modelColor: '',
  modelFont: '',
  title: '',
  titleColor: '',
  titleFont: '',
  bgColor: '',
  accentColor: '',
  narrowTitle: false,
  onNext: null,
};

const M6Title = styled(IntroTitle)`
  width: 100%;
`;

export default Intro;
