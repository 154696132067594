import React, { useEffect, useState } from 'react';
import { useDemoData } from '../../../hooks';
import StillNotSureModel from '../../../models/StillNotSureModel';

const parseStillNotSureData = demoData => {
  const data = new StillNotSureModel(demoData || {}).props;
  return data;
};

export const connect = WrappedComponent => props => {
  const demoData = useDemoData();
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    if (demoData) setCurrentData(parseStillNotSureData(demoData));
    return () => {};
  }, [demoData]);

  return <WrappedComponent {...currentData} {...props} />;
};

export default connect;
