import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontVariables, colors } from '../../../styles/variables';
import { ReactComponent as ArrowDown } from '../../../assets/svgs/circle-arrow-down.svg';
import { ID_TEXTS } from '../../../utils/constants';
import { normalizeScapedLineBreaks } from '../../../utils/string';

const {
  families: { primaryFont },
  weights: { bold, normal },
} = fontVariables;

const Feature = ({
  id,
  className,
  icon,
  iconAlt,
  model,
  modelColor,
  modelFont,
  title,
  titleColor,
  titleFont,
  description,
  footnote,
  descriptionColor,
  accentColor,
  noArrow,
  onNext,
}) => (
  <Container id={id} className={className}>
    <Icon src={icon} alt={iconAlt} />
    <ModelName color={modelColor} font={modelFont}>
      {model}
    </ModelName>
    <Title color={titleColor} font={titleFont}>
      {normalizeScapedLineBreaks(title)}
    </Title>
    <TextWrapper>
      <Description color={descriptionColor} font={titleFont}>
        {normalizeScapedLineBreaks(description)}
      </Description>
      {footnote && (
        <Footnote color={descriptionColor} font={titleFont}>
          {normalizeScapedLineBreaks(footnote)}
        </Footnote>
      )}
    </TextWrapper>
    {!noArrow && (
      <div id={ID_TEXTS.FEATURE_DOWN_ARROW}>
        {onNext ? (
          <DownButton type="button" onClick={onNext}>
            <DownArrow color={accentColor} />
          </DownButton>
        ) : (
          <DownArrow color={accentColor} />
        )}
      </div>
    )}
  </Container>
);

const Container = styled.div`
  color: ${colors.white};

  max-width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(200)
      : theme.webRT.getWidthValue(450)}px;
`;

export const Icon = styled.img`
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(30)
      : theme.webRT.getWidthValue(50)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(30)
      : theme.webRT.getWidthValue(50)}px;
  margin-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(35)
      : theme.webRT.getWidthValue(30)}px;
`;

const ModelName = styled.p`
  &&& {
    font-family: ${({ font }) => font || primaryFont};
    font-weight: ${bold};
    color: ${({ color }) => color};
    margin: 0;

    letter-spacing: ${({ theme }) => (theme.isMobile ? 0.16 : 0.31)}px;
    line-height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(20)
        : theme.webRT.getWidthValue(39)}px;
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(16)
        : theme.webRT.getFontSize(31)}px;
    margin-bottom: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(15)
        : theme.webRT.getWidthValue(23)}px;
  }
`;

const Title = styled.h3`
  &&& {
    white-space: pre-wrap;
    font-family: ${({ font }) => font || primaryFont};
    font-weight: ${normal};
    color: ${({ color }) => color};
    margin: 0;

    letter-spacing: ${({ theme }) => (theme.isMobile ? 0.32 : 0.69)}px;
    line-height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(34)
        : theme.webRT.getWidthValue(73)}px;
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(32)
        : theme.webRT.getFontSize(69)}px;
    margin-bottom: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(22)
        : theme.webRT.getWidthValue(31)}px;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(35)
      : theme.webRT.getWidthValue(31)}px;
`;

const Description = styled.p`
  &&& {
    white-space: pre-wrap;
    font-family: ${({ font }) => font || primaryFont};
    font-weight: ${normal};
    color: ${({ color }) => color};
    margin: 0;

    letter-spacing: ${({ theme }) => (theme.isMobile ? 0.18 : 0.3)}px;
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(18)
        : theme.webRT.getFontSize(30)}px;
    line-height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(22)
        : theme.webRT.getWidthValue(36)}px;
  }
`;

const Footnote = styled(Description)`
  &&& {
    white-space: pre-wrap;
    font-family: ${({ font }) => font || primaryFont};
    color: ${({ color }) => color};
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(12)
        : theme.webRT.getFontSize(17)}px;
    margin-top: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(12)
        : theme.webRT.getWidthValue(20)}px;
    line-height: normal;
  }
`;

const DownArrow = styled(ArrowDown)`
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(30)
      : theme.webRT.getWidthValue(47)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(30)
      : theme.webRT.getWidthValue(47)}px;

  g,
  line,
  path {
    stroke: ${({ color }) => color};
  }
`;

const DownButton = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: none;
  appearance: none;

  ${DownArrow} {
    width: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(46)
        : theme.webRT.getWidthValue(46)}px;
    height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(46)
        : theme.webRT.getWidthValue(46)}px;
  }
`;

Feature.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  model: PropTypes.string,
  modelColor: PropTypes.string,
  modelFont: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  description: PropTypes.string,
  footnote: PropTypes.string,
  descriptionColor: PropTypes.string,
  accentColor: PropTypes.string,
  noArrow: PropTypes.bool,
  onNext: PropTypes.func,
};

Feature.defaultProps = {
  id: '',
  className: '',
  icon: '',
  iconAlt: '',
  model: '',
  modelColor: '',
  modelFont: '',
  title: '',
  titleColor: '',
  titleFont: '',
  description: '',
  footnote: '',
  descriptionColor: '',
  accentColor: '',
  noArrow: false,
  onNext: null,
};

export default Feature;
