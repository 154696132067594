import { Power2, gsap } from 'gsap';
import { ID_TEXTS } from '../../../utils/constants';

export function createStartScreenTimeline({ element }) {
  return gsap
    .timeline()
    .to(element.querySelector(`#${ID_TEXTS.INTRO_CONTENT}`), {
      opacity: 0,
      zIndex: -1,
    })
    .to(
      element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`),
      {
        top: 0,
      },
      0
    );
}

export function createStartTopScreenTimeline({ element, attributes }) {
  return gsap
    .timeline()
    .to(element.querySelector(`#${ID_TEXTS.INTRO_CONTENT}`), {
      opacity: 0,
      zIndex: -1,
    })
    .to(
      element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`),
      {
        ...attributes,
      },
      0
    );
}

export function createFadeOutColorLine({ element, duration = 0.2 }) {
  return gsap.timeline().to(element.querySelector(`#${ID_TEXTS.COLOR_LINE}`), {
    opacity: 0,
    duration,
  });
}

export function createImageToTopTimeline({ element, imageHeight, scale }) {
  const yImage = (imageHeight * scale) / 1.9;
  return gsap.timeline().to(
    element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`),
    {
      y: -yImage,
      scale,
      transformOrigin: 'center top',
    },
    0
  );
}

export function createFadeInSlidesTimeline({ element, imageHeight }) {
  const ySlide1 = imageHeight / 1.85;
  const ySlide2 = imageHeight / 2.1;
  const xSlide1 = imageHeight / 3;
  const xSlide2 = imageHeight / 4.25;
  return gsap
    .timeline()
    .to(element.querySelector(`#${ID_TEXTS.SLIDE_1}`), {
      y: ySlide1,
      x: -xSlide1,
      opacity: 1,
      scale: 1.1,
    })
    .to(
      element.querySelector(`#${ID_TEXTS.SLIDE_2}`),
      {
        y: ySlide2,
        x: xSlide2,
        opacity: 1,
        scale: 1.3,
        transformOrigin: 'center top',
      },
      0
    );
}

export function createFadeOutSlidesTimeline({ element }) {
  return gsap
    .timeline()
    .to(element.querySelector(`#${ID_TEXTS.SLIDE_1}`), {
      opacity: 0,
      duration: 0.1,
    })
    .to(
      element.querySelector(`#${ID_TEXTS.SLIDE_2}`),
      {
        opacity: 0,
        duration: 0.1,
      },
      0
    );
}

export function createSlideUpFeatureTimeline({
  element,
  feature,
  containerHeight,
}) {
  return gsap.timeline().to(
    element.querySelector(feature),
    {
      opacity: 0,
      zIndex: 0,
      top: -containerHeight,
    },
    0
  );
}

export function createShowFeatureTimeline({
  element,
  prevFeature,
  nextFeature,
}) {
  const showNextFeature = gsap
    .timeline()
    .to(element.querySelector(nextFeature), {
      top: 0,
      opacity: 1,
      zIndex: 100,
    });
  if (prevFeature) {
    return showNextFeature.to(
      element.querySelector(prevFeature),
      {
        opacity: 0,
        zIndex: 0,
        duration: 0.2,
      },
      0
    );
  }
  return showNextFeature;
}

export function createImageRotationTimeline({
  element,
  startFrame,
  endFrame,
  attributes,
  onUpdate = () => {},
}) {
  const imageRotationFrames = {
    frame: startFrame,
  };
  const rotateImage = gsap.timeline().to(imageRotationFrames, {
    frame: endFrame,
    ease: Power2.easeInOut,
    onUpdate: () => onUpdate(Math.round(imageRotationFrames.frame)),
  });
  if (attributes) {
    return rotateImage.to(
      element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`),
      {
        ...attributes,
      },
      0
    );
  }
  return rotateImage;
}

export function fadeInFromRight({ element }) {
  const fadeIn = gsap.timeline().to(
    element.querySelector(`#${ID_TEXTS.IMAGE_FADE_RIGHT_2}`),
    {
      left: '50%',
      opacity: 1,
      scale: 1.9,
    },
    0
  );
  return fadeIn;
}

export function hideSideImage({ element, image }) {
  return gsap.timeline().to(
    element.querySelector(`#${image}`),
    {
      left: '100%',
      opacity: 0,
      zIndex: 0,
      duration: 0.2,
    },
    0
  );
}
