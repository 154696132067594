import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontVariables } from '../../../styles/variables';
import useResponsiveTools from '../../../hooks/useResponsiveTools';
import { normalizeScapedLineBreaks } from '../../../utils/string';

const {
  families: { primaryFont },
  weights: { semibold },
} = fontVariables;

const GeniousCarouselTitle = ({
  title,
  titleColor,
  bgColor,
  titleFont,
  bgImageMb,
}) => {
  const responsiveUtilities = useResponsiveTools();
  return (
    <Container bgColor={bgColor} {...responsiveUtilities}>
      <TitleContainer bgImageMb={bgImageMb} {...responsiveUtilities}>
        <Title color={titleColor} {...responsiveUtilities} font={titleFont}>
          {normalizeScapedLineBreaks(title)}
        </Title>
      </TitleContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${props => props.isMobile && props.bgColor};
  background-image: url(${({ bgColor }) => bgColor});
`;

const TitleContainer = styled.div`
  min-height: ${props =>
    props.isMobile
      ? props.mobileRT.getWidthValue(250)
      : props.webRT.getWidthValue(67)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => (props.isMobile ? props.bgImageMb : '')});
`;

const Title = styled.h2`
  white-space: pre-wrap;
  margin: 0;
  line-height: normal;
  font-family: ${({ font }) => font || primaryFont};
  font-weight: ${semibold};
  color: ${({ color }) => color};
  font-size: ${props =>
    props.isMobile
      ? props.mobileRT.getFontSize(32)
      : props.webRT.getFontSize(56)}px;
  text-align: center;
  margin-top: ${props =>
    !props.isMobile ? props.webRT.getWidthValue(74) : 0}px;
  margin-bottom: ${props =>
    !props.isMobile ? props.webRT.getWidthValue(45) : 0}px;
`;

GeniousCarouselTitle.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  bgColor: PropTypes.string,
  titleFont: PropTypes.string,
  bgImageMb: PropTypes.string,
};

GeniousCarouselTitle.defaultProps = {
  title: '',
  titleColor: '',
  bgColor: '',
  titleFont: '',
  bgImageMb: '',
};

export default GeniousCarouselTitle;
