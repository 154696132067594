import PropTypes from 'prop-types';

export const SlideType = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  footNote: PropTypes.string,
  mediaFormat: PropTypes.string,
  mediaUrl: PropTypes.string,
  altText: PropTypes.string,
  duration: PropTypes.string,
  thumbUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  slideIndex: PropTypes.number,
  prevIndex: PropTypes.number,
  currentIndex: PropTypes.number,
  groupIndex: PropTypes.number,
  groupSize: PropTypes.number,
  bgColor: PropTypes.string,
  isVisible: PropTypes.bool,
};

export const CarouselNavItemType = {
  title: PropTypes.string,
  icon: PropTypes.string,
  targetIndex: PropTypes.number,
};

export const ProductCardType = {
  logoUrl: PropTypes.string,
  header: PropTypes.string,
  headerColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  prodImage: PropTypes.string,
  seriesTitle: PropTypes.string,
  seriesDescription: PropTypes.string,
  exploreUrl: PropTypes.string,
  exploreText: PropTypes.string,
};

export default {
  SlideType,
  CarouselNavItemType,
  ProductCardType,
};
