import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontVariables, colors } from '../../../styles/variables';
import { useResponsiveTools } from '../../../hooks';
import { ReactComponent as IconCaret } from '../../../assets/svgs/icon-caret.svg';
import { normalizeScapedLineBreaks } from '../../../utils/string';

const {
  families: { primaryFont, secondaryFont },
  weights,
} = fontVariables;

const SlideContent = ({
  title,
  subtitle,
  description,
  footNote,
  isOpen,
  bgColor,
}) => {
  const { isMobile: isScreenMobile } = useResponsiveTools();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <Content
      className={`${isOpen ? 'genius-carousel__slide-content--is-open' : ''}`}
    >
      <HeaderWrapper bgColor={bgColor}>
        <header>
          <Title>{normalizeScapedLineBreaks(title)}</Title>
          <Subtitle>{normalizeScapedLineBreaks(subtitle)}</Subtitle>
        </header>
      </HeaderWrapper>
      <Separator />
      <DescriptionDrawer isOpen={isDrawerOpen}>
        {isScreenMobile && (
          <DrawerButton
            type="button"
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
          >
            <span>{isDrawerOpen ? 'Close' : 'Open'}</span>
            <StyledIconCaret isOpen={isDrawerOpen}>
              <IconCaret />
            </StyledIconCaret>
          </DrawerButton>
        )}
        <DescriptionContent isOpen={isDrawerOpen}>
          <Description isOpen={isDrawerOpen}>
            {normalizeScapedLineBreaks(description)}
          </Description>
          {footNote && (
            <FootNote>{normalizeScapedLineBreaks(footNote)}</FootNote>
          )}
        </DescriptionContent>
      </DescriptionDrawer>
    </Content>
  );
};

const Content = styled.section`
  width: ${({ theme }) =>
    theme.isMobile ? '100%' : `${theme.webRT.getWidthValue(575)}px`};
  height: ${({ theme }) =>
    theme.isMobile ? 'auto' : `${theme.webRT.getWidthValue(687)}px`};
  background-color: ${({ theme }) =>
    theme.isMobile ? 'transparent' : '#FFFFFF'};
  border-radius: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(0)
      : theme.webRT.getWidthValue(19)}px;
  padding: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(0)
        : theme.webRT.getWidthValue(0)}px
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(0)
        : theme.webRT.getWidthValue(70)}px
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(0)
        : theme.webRT.getWidthValue(0)}px
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(0)
        : theme.webRT.getWidthValue(120)}px;
  display: ${({ theme }) => (theme.isMobile ? 'block' : 'flex')};
  z-index: ${({ theme }) => (theme.isMobile ? '9' : '0')};
  transition: transform 0.85s ease-in-out;
  transform: translate(${({ theme }) => (theme.isMobile ? 0 : '-95%')}, 0);
  box-shadow: 10px 25px 25px rgba(0, 0, 0, 0);
  position: relative;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(-18)}px` : 'auto'};
  flex-direction: column;
  justify-content: center;

  header {
    display: ${({ theme }) => (theme.isMobile ? 'flex' : 'block')};
    flex-direction: column;
    justify-content: center;
    padding: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(24)
        : theme.webRT.getWidthValue(0)}px;
    padding-bottom: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(35)
        : theme.webRT.getWidthValue(0)}px;
    text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
    border-radius: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(40)
        : theme.webRT.getWidthValue(0)}px;
    background-color: ${({ theme }) =>
      theme.isMobile ? '#FFFFFF' : 'transparent'};
    height: ${({ theme }) =>
      theme.isMobile ? `${theme.mobileRT.getWidthValue(200)}px` : 'auto'};
    box-shadow: 0
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(-3)
          : theme.webRT.getWidthValue(0)}px
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(5)
          : theme.webRT.getWidthValue(0)}px
      rgba(0, 0, 0, 0.15);
  }

  &.genius-carousel__slide-content--is-open {
    transform: translate(
      -${({ theme }) => (theme.isMobile ? theme.mobileRT.getWidthValue(0) : theme.webRT.getWidthValue(60))}px,
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(0)
          : theme.webRT.getWidthValue(63)}px
    );
    box-shadow: ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(0)
          : theme.webRT.getWidthValue(10)}px
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(0)
          : theme.webRT.getWidthValue(10)}px
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(0)
          : theme.webRT.getWidthValue(25)}px
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(0)
          : theme.webRT.getWidthValue(25)}px
      rgba(0, 0, 0, 0.08);
  }
`;

const HeaderWrapper = styled.div`
  background-color: ${({ theme, bgColor }) =>
    theme.isMobile ? bgColor : 'transparent'};
  position: ${({ theme }) => (theme.isMobile ? `relative` : 'static')};
  z-index: ${({ theme }) => (theme.isMobile ? '3' : '0')};
  border-top-left-radius: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(40)
      : theme.webRT.getWidthValue(0)}px;
  border-top-right-radius: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(40)
      : theme.webRT.getWidthValue(0)}px;
  padding-bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(18)}px` : 'auto'};
`;

const Title = styled.h2`
  white-space: pre-wrap;
  color: ${colors.grey1};
  font-family: ${primaryFont};
  font-size: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getFontSize(16)
      : theme.webRT.getFontSize(26)}px;
  font-weight: ${weights.bold};
  letter-spacing: 0.26px;
  text-align: left;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(14)
      : theme.webRT.getWidthValue(26)}px;
  text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
`;

const Subtitle = styled.h3`
  white-space: pre-wrap;
  color: ${colors.grey1};
  font-family: ${primaryFont};
  font-size: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getFontSize(18)
      : theme.webRT.getFontSize(28)}px;
  font-weight: ${weights.normal};
  letter-spacing: 0.28px;
  line-height: ${32 / 28};
  text-align: left;
  margin: 0;
  margin-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(0)
      : theme.webRT.getWidthValue(30)}px;
  text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
  overflow-y: auto;
`;

const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.grey2};
  height: 0;
  margin: 0;
  margin-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(0)
      : theme.webRT.getWidthValue(26)}px;
  display: ${({ theme }) => (theme.isMobile ? 'none' : 'block')};
`;

const DescriptionDrawer = styled.div`
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(311)}px` : 'auto'};
  background-color: ${({ theme }) => theme.isMobile && colors.gray4};
  border-radius: ${({ theme }) =>
    theme.isMobile && theme.mobileRT.getWidthValue(40)}px;
  padding: ${({ theme }) =>
      theme.isMobile && theme.mobileRT.getWidthValue(57)}px
    ${({ theme }) => theme.isMobile && theme.mobileRT.getWidthValue(40)}px;
  text-align: ${({ theme }) => theme.isMobile && 'center'};
  position: ${({ theme }) => (theme.isMobile ? 'absolute' : 'static')};
  bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(58)}px` : 'auto'};
  transform: ${({ theme, isOpen }) => {
    if (theme.isMobile) {
      if (isOpen) {
        return `translate3d(0, ${theme.mobileRT.getWidthValue(-90)}px, 0)`;
      }
      return `translate3d(0, ${theme.mobileRT.getWidthValue(132)}px, 0)`;
    }
    return 'none';
  }};

  z-index: ${({ theme }) => (theme.isMobile ? '2' : '0')};
  transition: ${({ theme }) =>
    theme.isMobile ? 'transform .75s cubic-bezier(.6,1.24,.77,1.01)' : 'none'};
`;

const DescriptionContent = styled.div`
  opacity: ${({ theme, isOpen }) => (theme.isMobile && !isOpen ? '0' : '1')};
  transition: opacity 0.45s linear;
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(170)}px` : 'auto'};
  overflow: ${({ theme }) => theme.isMobile && 'auto'};
  padding-right: ${({ theme }) =>
    theme.isMobile && `${theme.mobileRT.getWidthValue(7)}px`};
`;

const Description = styled.p`
  &&& {
    white-space: pre-wrap;
    color: ${colors.grey1};
    font-family: ${secondaryFont};
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(16)
        : theme.webRT.getFontSize(20)}px;
    letter-spacing: 0.2px;
    line-height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(24)
        : theme.webRT.getWidthValue(28)}px;
    text-align: left;
    margin: 0;
    text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
  }
`;

const FootNote = styled.p`
  &&& {
    white-space: pre-wrap;
    color: ${colors.grey1};
    font-family: ${secondaryFont};
    font-size: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getFontSize(10)
        : theme.webRT.getFontSize(12)}px;
    letter-spacing: 0.2px;
    line-height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(14)
        : theme.webRT.getWidthValue(19)}px;
    margin: 0;
    margin-top: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(10)
        : theme.webRT.getWidthValue(10)}px;
    text-align: left;
    text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
  }
`;

const DrawerButton = styled.button`
  &&& {
    border: 0;
    padding: 0;
    margin: 0;
    width: ${({ theme }) =>
      theme.isMobile ? `${theme.mobileRT.getWidthValue(44)}px` : 'auto'};
    height: ${({ theme }) =>
      theme.isMobile ? `${theme.mobileRT.getWidthValue(51)}px` : 'auto'};
    border-radius: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(21)
        : theme.webRT.getWidthValue(0)}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.gray5};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -32%, 0);
    span {
      border: 0;
      clip: rect(0 0 0 0);
      height: auto;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
    }
  }
`;

const StyledIconCaret = styled.div`
  width: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
  height: ${({ theme }) => theme.mobileRT.getWidthValue(7)}px;
  transform: ${({ theme, isOpen }) =>
    theme.isMobile && isOpen ? 'rotate(180deg)' : 'none'};
  margin-top: -30%;
  transform-origin: 50% 100%;
  transition: transform 0.45s linear;
  svg {
    display: block;
    margin: 0 auto;
  }
`;

SlideContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  footNote: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  bgColor: PropTypes.string,
};

SlideContent.defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  footNote: '',
  bgColor: '',
};

export default SlideContent;
