export default class CarouselNavigationModel {
  constructor(navigationData = {}) {
    this.bgColor = navigationData?.bgColor || '#447355';
    this.textColor = navigationData?.textColor || '#FFFFFF';
    this.itemBgColor = navigationData?.itemBGColor || '#518a70';
    this.demoSource = navigationData?.showViewAll ? 'In-Store' : 'Website';
    this.productName = navigationData?.productName || 'na';
  }

  get props() {
    return {
      bgColor: this.bgColor,
      textColor: this.textColor,
      itemBgColor: this.itemBgColor,
      demoSource: this.demoSource,
      productName: this.productName,
    };
  }
}
