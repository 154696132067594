import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProductCard from '../ProductCard';
import StillNotSure from '../StillNotSure';
import { ProductCardType } from '../../../types';

const AllProducts = ({ title, products, hideStillNotSure }) => (
  <>
    <Container>
      {products.map(product => (
        <ProductCard key={product.uuid} {...product} />
      ))}
    </Container>
    {!hideStillNotSure && (
      <StillNotSure title={title} showBg={false} showViewAll={false} />
    )}
  </>
);

AllProducts.propTypes = {
  title: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(ProductCardType)),
  hideStillNotSure: PropTypes.bool,
};

AllProducts.defaultProps = {
  title: '',
  products: [],
  hideStillNotSure: false,
};

const Container = styled.div`
  display: flex;
  overflow-x: auto;
`;

export default AllProducts;
