const trackDemoLoad = (dataLayer = [], demoSource = '', productName = '') => {
  dataLayer.push({
    event: 'customBehavior',
    eventCategory: 'Product Demo Engagement',
    eventAction: `Demo Initiated | ${demoSource}`,
    eventLabel: `${productName}`,
  });
};

const trackButtonClick = (
  dataLayer = [],
  buttonName = '',
  productName = ''
) => {
  dataLayer.push({
    event: 'customBehavior',
    eventCategory: 'Product Demo Engagement',
    eventAction: buttonName,
    eventLabel: productName,
  });
};

const trackCategoryShow = (
  dataLayer = [],
  activeCategory = '',
  demoSource = '',
  productName = ''
) => {
  dataLayer.push({
    event: 'customBehavior',
    eventCategory: 'Product Demo Engagement',
    eventAction: `${activeCategory} Exposure | ${demoSource}`,
    eventLabel: productName,
  });
};

const onButtonClick = (dataLayer = [], buttonName = '', productName = '') => {
  try {
    trackButtonClick(dataLayer, buttonName, productName);
  } catch (error) {
    throw new Error(error);
  }
};

const onDemoLoadGA = (dataLayer = [], demoSource = '', productName = '') => {
  try {
    trackDemoLoad(dataLayer, demoSource, productName);
  } catch (error) {
    throw new Error(error);
  }
};

const onCategoryShow = (
  dataLayer = [],
  activeCategory = '',
  demoSource = '',
  productName = ''
) => {
  try {
    trackCategoryShow(dataLayer, activeCategory, demoSource, productName);
  } catch (error) {
    throw new Error(error);
  }
};

const ga = {
  onDemoLoadGA,
  onButtonClick,
  onCategoryShow,
};

export default ga;
