import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Balancer } from 'react-wrap-balancer';
import Stack from './Stack';
import device from '../../../utils/responsive/device';
import NavButton from './NavButton';
import { ReactComponent as ArrowDown } from '../../../assets/svgs/circle-arrow-down.svg';
import { fontVariables } from '../../../styles/variables';
import { normalizeScapedLineBreaks } from '../../../utils/string';

const MOBILE_TITLE_SMALL_FONT_SIZE = 44;
const MOBILE_TITLE_FONT_SIZE = 58;
const TITLE_LINE_HEIGHT = 1.1;

export default function StartScreen({
  logo,
  logoAlt,
  model,
  modelFont,
  modelColor,
  title,
  titleFont,
  titleColor,
  accentColor,
  height,
  onNext,
}) {
  const contentRef = useRef(null);
  const logoRef = useRef(null);
  const [titleFontSize, setTitleFontSize] = useState(MOBILE_TITLE_FONT_SIZE);

  useEffect(() => {
    if (!contentRef.current) {
      return undefined;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (contentRef.current.clientHeight >= height) {
        setTitleFontSize(MOBILE_TITLE_SMALL_FONT_SIZE);
      }
    });
    resizeObserver.observe(contentRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [height]);

  return (
    <StartScreenStack height={height}>
      <Stack spacing={3} ref={contentRef}>
        <LogoWrapper>
          <LogoImg ref={logoRef} src={logo} alt={logoAlt} />
        </LogoWrapper>
        <Stack spacing={2}>
          <ModelName font={modelFont} color={modelColor}>
            {model}
          </ModelName>
          <Title font={titleFont} color={titleColor} fontSize={titleFontSize}>
            <Balancer>{normalizeScapedLineBreaks(title)}</Balancer>
          </Title>
          <NavWrapper>
            <NavButton onClick={onNext} color={accentColor}>
              <ArrowDown />
            </NavButton>
          </NavWrapper>
        </Stack>
      </Stack>
    </StartScreenStack>
  );
}

StartScreen.propTypes = {
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  model: PropTypes.string,
  modelColor: PropTypes.string,
  modelFont: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  accentColor: PropTypes.string,
  height: PropTypes.number.isRequired,
  onNext: PropTypes.func,
};

StartScreen.defaultProps = {
  logo: '',
  logoAlt: '',
  model: '',
  modelColor: '',
  modelFont: '',
  title: '',
  titleColor: '',
  titleFont: '',
  accentColor: '',
  onNext: () => {},
};

const StartScreenStack = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height}px;
  padding: 24px 24px 0;

  @media ${device.laptop} {
    padding: 48px 96px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  width: 44px;
  height: 44px;

  @media ${device.xlarge} {
    width: 88px;
    height: 88px;
  }
`;

const ModelName = styled.div`
  font-family: ${({ font }) => font || fontVariables.primaryFont};
  font-weight: ${fontVariables.weights.bold};
  font-size: 22px;
  line-height: 1.1;
  letter-spacing: 0.22;
  color: ${({ color }) => color};
  text-align: center;
  margin: 0;
  padding: 0;

  @media ${device.tablet} {
    font-size: 32px;
    letter-spacing: 0.31;
  }

  @media ${device.xlarge} {
    font-size: 64px;
  }
`;

const Title = styled.h2`
  font-family: ${({ font }) => font || fontVariables.primaryFont};
  font-weight: ${fontVariables.weights.normal};
  font-size: ${({ fontSize }) => fontSize}px;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  color: ${({ color }) => color};
  text-align: center;
  letter-spacing: 0.58px;
  line-height: ${TITLE_LINE_HEIGHT};

  @media ${device.tablet} {
    font-size: 60px;
    letter-spacing: 1.6;
  }

  @media ${device.xlarge} {
    font-size: 120px;
    max-width: 1620px;
  }
`;

const NavWrapper = styled.div`
  align-self: center;
`;

export const StartScreenContainer = styled.div`
  position: relative;
  z-index: 4;
  height: ${({ height }) => height}px;

  @media ${device.tablet} {
    max-width: 600px;
    margin: 0 auto;
  }

  @media ${device.tablet} {
    max-width: 800px;
  }

  @media ${device.desktop} {
    max-width: 1000px;
  }

  @media ${device.xlarge} {
    max-width: 1600px;
  }
`;
