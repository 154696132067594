import React from 'react';
import styled from 'styled-components';
import ModelDetail from '../ModelDetail';
import GeniusCarousel from '../GeniusCarousel';
import ViewAllProducts from '../ViewAllProducts';
import Footer from '../Footer';
import ResponsiveTools from '../../utils/responsive';
import { ID_TEXTS } from '../../utils/constants';

const { isMobile } = ResponsiveTools;

const Container = () => (
  <StyledContainer>
    <ModelDetail />
    <div id={ID_TEXTS.MAIN_CONTENT}>
      <GeniusCarousel.GeniusCarouselWrapper />
      {isMobile && <ViewAllProducts />}
      {isMobile && <Footer />}
    </div>
  </StyledContainer>
);

const StyledContainer = styled.div`
  font-family: 'HarmoniaSans', serif;
  position: relative;
`;

export default Container;
