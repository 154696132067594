import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import CarouselModel from '../../../models/CarouselModel';
import { useDemoData } from '../../../hooks';

const parseTitleData = carouselData =>
  new CarouselModel(carouselData).titleProps;

export const connect = WrappedComponent => props => {
  const [currentTitleData, setCurrentTitleData] = useState({});
  const demoData = useDemoData();

  useEffect(() => {
    if (!isEmpty(demoData)) setCurrentTitleData(parseTitleData(demoData));
    return () => {};
  }, [demoData]);
  return <WrappedComponent {...currentTitleData} {...props} />;
};

export default connect;
