class SlideModel {
  constructor(slideData = {}) {
    // Declare properties and initialize with default values
    // Parse incoming data to our properties, using default values as fallback
    this.uuid = slideData?.uuid || '';
    this.title = slideData?.title || '';
    this.subTitle = slideData?.subtitle || '';
    this.description = slideData?.description || '';
    this.footnote = slideData?.legal_footnote || '';
    this.mediaFormat = slideData?.preferred_media_format || 'gif';
    this.mediaUrl =
      (slideData?.preferred_media_format === 'video'
        ? slideData?.video_url
        : slideData?.gif_url) || '';
    this.altText = slideData?.gif_alt_txt || '';
    this.thumbUrl = slideData?.gif_preview_url || '';
    this.duration = slideData?.gif_duration_in_secs || '';
    this.category = slideData?.category || '';
    this.groupSize = slideData?.groupSize;
    this.groupIndex = slideData?.groupIndex;
  }

  get props() {
    // These keys will be mapped to the props the React component will consume
    return {
      uuid: this.uuid,
      title: this.title,
      subtitle: this.subTitle,
      description: this.description,
      footNote: this.footnote,
      mediaFormat: this.mediaFormat,
      mediaUrl: this.mediaUrl,
      altText: this.altText,
      thumbUrl: this.thumbUrl,
      duration: this.duration,
      category: this.category,
      ...(this.groupSize >= 0 && { groupSize: this.groupSize }),
      ...(this.groupIndex >= 0 && { groupIndex: this.groupIndex }),
    };
  }
}

export default SlideModel;
