import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { propTypes, defaultProps } from '../propsFile';
import Intro, { IntroTitle } from '../Intro';
import Feature, { FeatureIcon } from '../Feature';
import Loader from '../Loader';
import useResponsiveTools from '../../../hooks/useResponsiveTools';
import { ID_TEXTS } from '../../../utils/constants';

const TOTAL_360_IMGS = 36;
const PRELOADING_THRESHOLD = Math.ceil(TOTAL_360_IMGS / 8);

const getImageUrlsArr = imagesBaseUrl =>
  [...Array(TOTAL_360_IMGS)].map((_, index) =>
    imagesBaseUrl.replace('{1}', String(index + 1))
  );

const J7Detail = ({
  logo,
  logoAlt,
  modelName,
  subtitleColor,
  subtitleFont,
  title,
  titleColor,
  titleFont,
  bgColor,
  accentColor,
  featuresTitleColor,
  featuresModelNameColor,
  featuresDescriptionColor,
  features,
  imagesBaseUrl,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollToRef = useRef(null);
  const responsiveUtilities = useResponsiveTools();
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [loadedImages, setLoadedImages] = useState(0);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef();
  const urlsArr = useMemo(
    () => getImageUrlsArr(imagesBaseUrl),
    [imagesBaseUrl]
  );
  const mainTimeLineRef = useRef(null);
  const scrollTriggerRef = useRef(null);

  const renderImages = useCallback(
    imgsArr =>
      imgsArr.map((src, index) => (
        <StyledImage
          key={`j7-image-${index + 1}`}
          src={src}
          alt={`j7 image ${index + 1}`}
          active={index + 1 === currentImageIndex}
          onLoad={() => {
            setLoadedImages(l => l + 1);
          }}
        />
      )),
    [currentImageIndex]
  );

  useEffect(() => {
    const { isMobile, mobileRT, webRT } = responsiveUtilities;
    const element = containerRef.current;

    if (!loading && element && features) {
      const imageRotation = frame => {
        setCurrentImageIndex(Math.round(frame));
      };

      // -- Scene 1

      const scene1IntroAnimation = () => {
        const tl = gsap.timeline();
        tl.to(
          element.querySelector(`#${ID_TEXTS.INTRO_CONTENT}`),
          {
            opacity: 0,
          },
          0
        );
        return tl;
      };

      const scene1ImageAnimation = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`), {
          y: isMobile
            ? mobileRT.getWidthValue(-380)
            : webRT.getWidthValue(-560),
        });
        return tl;
      };

      const scene1Timeline = () => {
        const tl = gsap.timeline();
        tl.add(scene1IntroAnimation());
        tl.add(scene1ImageAnimation(), 0);
        return tl;
      };

      // -- Scene 2

      const scene2RotationFrames = {
        frame: 1,
      };

      const scene2LogoAnimation = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.INTRO_LOGO}`), {
          y: isMobile ? mobileRT.getWidthValue(-70) : webRT.getWidthValue(-150),
          onStart: () => {
            element.querySelector(
              `#${ID_TEXTS.INTRO_CONTENT}`
            ).style.visibility = 'hidden';
          },
          onReverseComplete: () => {
            element.querySelector(
              `#${ID_TEXTS.INTRO_CONTENT}`
            ).style.visibility = 'visible';
          },
        });
        return tl;
      };

      const scene2ColorLineAnimation = () => {
        const tl = gsap.timeline();
        tl.to(
          element.querySelector(`#${ID_TEXTS.COLOR_LINE}`),
          {
            opacity: 0,
          },
          0
        );
        return tl;
      };

      const scene2FeatureAnimation = () => {
        const tl = gsap.timeline();
        tl.to(
          element.querySelector(`#${ID_TEXTS.FEATURE_1}`),
          {
            y: isMobile
              ? mobileRT.getWidthValue(-36)
              : webRT.getWidthValue(-240),
            opacity: 1,
            zIndex: 100,
          },
          0
        );
        tl.from(
          element.querySelector(`#${ID_TEXTS.FEATURE_DOWN_ARROW}`),
          {
            y: isMobile ? mobileRT.getWidthValue(30) : webRT.getWidthValue(30),
          },
          0
        );
        return tl;
      };

      const scene2ImageAnimation = () => {
        const tl = gsap.timeline();
        tl.to(
          element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`),
          {
            x: isMobile ? mobileRT.getWidthValue(160) : webRT.getWidthValue(0),
            y: isMobile
              ? mobileRT.getWidthValue(-60)
              : webRT.getWidthValue(-320),
            scale: isMobile ? 2 : 2.5,
          },
          0
        );
        tl.to(
          scene2RotationFrames,
          {
            frame: 19,
            onUpdate: () => imageRotation(scene2RotationFrames.frame),
          },
          0
        );
        return tl;
      };

      const scene2Timeline = () => {
        const tl = gsap.timeline();
        tl.add(scene2LogoAnimation());
        tl.add(scene2ColorLineAnimation(), 0);
        tl.add(scene2ColorLineAnimation(), 0);
        tl.add(scene2ImageAnimation(), 0);
        tl.add(scene2FeatureAnimation(), 0.2);
        return tl;
      };

      // -- Scene 3

      const scene3FeatureAnimation = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_1}`), {
          opacity: 0,
          zIndex: 0,
          y: isMobile
            ? mobileRT.getWidthValue(-200)
            : webRT.getWidthValue(-200),
        });
        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_2}`), {
          y: isMobile ? mobileRT.getWidthValue(-36) : webRT.getWidthValue(-240),
          opacity: 1,
          zIndex: 100,
        });
        return tl;
      };

      const scene3ImageAnimation = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`), {
          x: isMobile ? mobileRT.getWidthValue(0) : webRT.getWidthValue(0),
          y: isMobile ? mobileRT.getWidthValue(-48) : webRT.getWidthValue(0),
          scale: isMobile ? 0.9 : 1.5,
        });
        return tl;
      };

      const scene3Timeline = () => {
        const tl = gsap.timeline();
        tl.add(scene3ImageAnimation());
        tl.add(scene3FeatureAnimation(), 0);
        return tl;
      };

      // -- Scene 4

      const scene4RotationFrames = {
        frame: 19,
      };

      const scene4FeatureAnimation = () => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_2}`), {
          opacity: 0,
          zIndex: 0,
          y: isMobile
            ? mobileRT.getWidthValue(-200)
            : webRT.getWidthValue(-200),
        });
        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_3}`), {
          y: isMobile ? mobileRT.getWidthValue(-36) : webRT.getWidthValue(-240),
          opacity: 1,
          zIndex: 100,
        });

        return tl;
      };

      const scene4ImageAnimation = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`), {
          x: isMobile ? mobileRT.getWidthValue(110) : webRT.getWidthValue(40),
          y: isMobile ? mobileRT.getWidthValue(550) : webRT.getWidthValue(600),
          scale: isMobile ? 4 : 2,
        });
        tl.to(
          scene4RotationFrames,
          {
            frame: 36,
            onUpdate: () => imageRotation(scene4RotationFrames.frame),
          },
          0
        );
        return tl;
      };

      const scene4Timeline = () => {
        const tl = gsap.timeline();
        tl.add(scene4ImageAnimation());
        tl.add(scene4FeatureAnimation(), 0);
        return tl;
      };

      // -- Scene 5

      const scene5RotationFrames = {
        frame: 36,
      };

      const scene5FeatureAnimation = () => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_3}`), {
          opacity: 0,
          zIndex: 0,
          y: isMobile
            ? mobileRT.getWidthValue(-200)
            : webRT.getWidthValue(-200),
        });
        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_4}`), {
          y: isMobile ? mobileRT.getWidthValue(-36) : webRT.getWidthValue(-240),
          opacity: 1,
          zIndex: 100,
        });

        return tl;
      };

      const scene5ImageAnimation = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`), {
          x: isMobile ? mobileRT.getWidthValue(50) : webRT.getWidthValue(160),
          y: isMobile ? mobileRT.getWidthValue(100) : webRT.getWidthValue(200),
          scale: isMobile ? 2 : 2,
        });
        tl.to(
          scene5RotationFrames,
          {
            frame: 24,
            onUpdate: () => imageRotation(scene5RotationFrames.frame),
          },
          0
        );
        return tl;
      };

      const scene5Timeline = () => {
        const tl = gsap.timeline();
        tl.add(scene5ImageAnimation());
        tl.add(scene5FeatureAnimation(), 0);
        return tl;
      };

      // -- Scene 6

      const scene6RotationFrames = {
        frame: 24,
      };

      const scene6RotationFrames2 = {
        frame: 1,
      };

      const scene6FeatureAnimation = () => {
        const tl = gsap.timeline();

        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_4}`), {
          opacity: 0,
          zIndex: 0,
          y: isMobile
            ? mobileRT.getWidthValue(-200)
            : webRT.getWidthValue(-200),
        });
        tl.to(element.querySelector(`#${ID_TEXTS.FEATURE_5}`), {
          y: isMobile ? mobileRT.getWidthValue(-36) : webRT.getWidthValue(-240),
          opacity: 1,
          zIndex: 100,
        });

        return tl;
      };

      const scene6ImageScale = () => {
        const tl = gsap.timeline();
        tl.to(element.querySelector(`#${ID_TEXTS.IMAGES_WRAPPER}`), {
          x: isMobile ? mobileRT.getWidthValue(100) : webRT.getWidthValue(120),
          y: isMobile ? mobileRT.getWidthValue(60) : webRT.getWidthValue(0),
          scale: isMobile ? 1.6 : 1.5,
        });
        return tl;
      };

      const scene6ImageAnimation = () => {
        const tl = gsap.timeline();
        tl.to(
          scene6RotationFrames,
          {
            frame: 36,
            onUpdate: () => imageRotation(scene6RotationFrames.frame),
          },
          0
        );
        tl.to(scene6RotationFrames2, {
          frame: 19,
          onUpdate: () => imageRotation(scene6RotationFrames2.frame),
        });
        return tl;
      };

      const scene6Timeline = () => {
        const tl = gsap.timeline();
        tl.add(scene6FeatureAnimation());
        tl.add(scene6ImageScale(), 0);
        tl.add(scene6ImageAnimation(), 0);
        return tl;
      };

      // -- Main - Scenes 1 - 5

      const mainTimeLine = gsap.timeline();
      mainTimeLine.addLabel('step1');
      mainTimeLine.add(scene1Timeline(), '+=0.5');
      mainTimeLine.addLabel('step2');
      mainTimeLine.add(scene2Timeline(), '+=0.5');
      mainTimeLine.addLabel('step3');
      mainTimeLine.add(scene3Timeline(), '+=0.5');
      mainTimeLine.addLabel('step4');
      mainTimeLine.add(scene4Timeline(), '+=0.5');
      mainTimeLine.addLabel('step5');
      mainTimeLine.add(scene5Timeline(), '+=0.5');
      mainTimeLine.addLabel('step6');
      mainTimeLine.add(scene6Timeline(), '+=0.5');
      mainTimeLine.set({}, {}, '+=0.5');
      mainTimeLine.addLabel('step7');

      const scrollTrigger = ScrollTrigger.create({
        animation: mainTimeLine,
        trigger: element,
        start: 'top top',
        end: '+=600%',
        scrub: true,
        pin: true,
      });

      mainTimeLineRef.current = mainTimeLine;
      scrollTriggerRef.current = scrollTrigger;
    }
  }, [loading, responsiveUtilities, features]);

  useEffect(() => {
    if (loadedImages >= PRELOADING_THRESHOLD && loading) {
      setLoading(false);
    }
    return () => {};
  }, [loadedImages, loading]);

  function handleNext(label, duration = 3) {
    const mainTimeline = mainTimeLineRef.current;
    const scrollTrigger = scrollTriggerRef.current;
    if (mainTimeline && scrollTrigger) {
      const targetLabel = mainTimeline.labels[label];
      if (targetLabel) {
        const percent = targetLabel / mainTimeline.totalDuration();
        const scrollPos =
          scrollTrigger.start +
          (scrollTrigger.end - scrollTrigger.start) * percent;
        if (scrollToRef.current) {
          scrollToRef.current.kill();
        }
        scrollToRef.current = gsap.to(window, {
          duration,
          scrollTo: scrollPos,
          onComplete: () => {
            scrollToRef.current = null;
          },
        });
      }
    }
  }

  function handleEnd() {
    if (scrollToRef.current) {
      scrollToRef.current.kill();
    }
    scrollToRef.current = gsap.to(window, {
      duration: 1,
      scrollTo: `#${ID_TEXTS.MAIN_CONTENT_REF}`,
      onComplete: () => {
        scrollToRef.current = null;
      },
    });
  }

  return (
    <>
      <LoaderWrapper $loading={loading}>
        <Loader bgColor={bgColor} color={accentColor} />
      </LoaderWrapper>
      <Container $loading={loading} ref={containerRef} bgColor={bgColor}>
        {logo && (
          <CustomIntro
            logo={logo}
            logoAlt={logoAlt}
            model={modelName}
            modelColor={subtitleColor}
            modelFont={subtitleFont}
            title={title}
            titleColor={titleColor}
            titleFont={titleFont}
            bgColor={bgColor}
            accentColor={accentColor}
            onNext={() => handleNext('step3', 4)}
          />
        )}
        <ImagesContainer id={ID_TEXTS.IMAGES_CONTAINER}>
          <ImagesWrapper id={ID_TEXTS.IMAGES_WRAPPER}>
            {renderImages(urlsArr)}
          </ImagesWrapper>
          <ColorLine id={ID_TEXTS.COLOR_LINE} color={accentColor} />
        </ImagesContainer>
        {features && !!features.length && (
          <>
            <FeatureBottom
              id={ID_TEXTS.FEATURE_1}
              icon={features[0]?.icon_url}
              iconAlt={features[0]?.icon_alt_txt}
              model={features[0]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[0]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[0]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[0]?.legal_footnote}
              onNext={() => handleNext('step4')}
            />
            <FeatureBottom
              id={ID_TEXTS.FEATURE_2}
              icon={features[1]?.icon_url}
              iconAlt={features[1]?.icon_alt_txt}
              model={features[1]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[1]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[1]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[1]?.legal_footnote}
              onNext={() => handleNext('step5', 4)}
            />
            <FeatureCenter
              id={ID_TEXTS.FEATURE_3}
              icon={features[2]?.icon_url}
              iconAlt={features[2]?.icon_alt_txt}
              model={features[2]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[2]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[2]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[2]?.legal_footnote}
              onNext={() => handleNext('step6')}
            />
            <FeatureCenter
              id={ID_TEXTS.FEATURE_4}
              icon={features[3]?.icon_url}
              iconAlt={features[3]?.icon_alt_txt}
              model={features[3]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[3]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[3]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[3]?.legal_footnote}
              onNext={() => handleNext('step7', 3.5)}
            />
            <FeatureCenter
              id={ID_TEXTS.FEATURE_5}
              icon={features[4]?.icon_url}
              iconAlt={features[4]?.icon_alt_txt}
              model={features[4]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[4]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[4]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[4]?.legal_footnote}
              onNext={() => handleEnd()}
            />
          </>
        )}
      </Container>
    </>
  );
};

const LoaderWrapper = styled.div`
  display: ${({ $loading }) => ($loading ? 'block' : 'none')};
`;

const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: ${({ $loading }) => ($loading ? 'none' : 'block')};
`;

const ImagesContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => (theme.isMobile ? '100%' : '40%')};
  left: ${({ theme }) => (theme.isMobile ? '0' : '50%')};
  transform: ${({ theme }) => (theme.isMobile ? 'none' : 'translate(-50%, 0)')};
  height: ${({ theme }) =>
    theme.isMobile
      ? `calc(100vh - ${theme.mobileRT.getWidthValue(40)}px)`
      : `calc(100vh - ${theme.mobileRT.getWidthValue(10)}px)`};
  top: ${({ theme }) =>
    theme.isMobile ? 'auto' : `${theme.webRT.getWidthValue(730)}px`};
`;

const ColorLine = styled.div`
  background-color: ${({ color }) => color};
  flex: 1;
  z-index: 1;
  margin-top: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(-85)
      : theme.webRT.getWidthValue(-165)}px;
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(166)
      : theme.webRT.getWidthValue(396)}px;
`;

const FeatureBottom = styled(Feature)`
  position: absolute;
  z-index: -1;
  opacity: 0;
  top: ${({ theme }) =>
    theme.isMobile
      ? 'auto'
      : `calc(50% + ${theme.webRT.getWidthValue(240)}px)`};
  bottom: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(0)}px` : 'auto'};
  left: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(35)
      : theme.webRT.getWidthValue(169)}px;
  transform: ${({ theme }) => (theme.isMobile ? 'none' : 'translateY(-50%)')};

  ${FeatureIcon} {
    width: auto;
    max-width: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(60)
        : theme.webRT.getWidthValue(60)}px;
    height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(30)
        : theme.webRT.getWidthValue(60)}px;
    margin-bottom: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(16)
        : theme.webRT.getWidthValue(16)}px;
  }
`;

const FeatureCenter = styled(FeatureBottom)`
  bottom: auto;
  top: ${({ theme }) =>
    theme.isMobile
      ? `calc(50% + ${theme.mobileRT.getWidthValue(36)}px)`
      : `calc(50% + ${theme.webRT.getWidthValue(240)}px)`};
  transform: translateY(-50%);
`;

const ImagesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
  padding-bottom: 100%;
`;

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const CustomIntro = styled(Intro)`
  ${IntroTitle} {
    margin-right: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(50)
        : theme.webRT.getWidthValue(50)}px;
    margin-left: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(50)
        : theme.webRT.getWidthValue(50)}px;
  }
`;

J7Detail.propTypes = propTypes;

J7Detail.defaultProps = defaultProps;

export default J7Detail;
