import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useDemoData } from '../../hooks';

const parseFooterData = data => {
  const parsedData = {
    logoUrl: data.footer_logo_url,
    logoAlt: data.footer_logo_alt_txt,
    bgColor: data.footer_backgroud_color,
  };
  return parsedData;
};

export const connect = WrappedComponent => props => {
  const [footerData, setFooterData] = useState({});
  const demoData = useDemoData();

  useEffect(() => {
    if (!isEmpty(demoData)) setFooterData(parseFooterData(demoData));
    return () => {};
  }, [demoData]);

  return <WrappedComponent {...footerData} {...props} />;
};

export default connect;
