import { colors } from '../styles/variables';

export default class StillNotSureModel {
  constructor(data) {
    this.title = data?.end_title || 'Still Not Sure?';
    this.titleColor = data?.end_title_text_color || colors.green4;
    this.titleFont = data?.end_title_font || '';
    this.bgImage = data?.end_mobile_background_image_url || '';
    this.bgImageAlt = data?.end_mobile_background_image_url || '';
    this.bgColor = data?.end_title_background_color || colors.green5;
    this.showViewAllProductsButton =
      data?.end_show_view_all_products_button || false;
    this.viewAllProductsButtonColor =
      data?.end_show_view_all_products_button_color || colors.green4;
    this.viewAllProductsButtonText =
      data?.end_show_view_all_products_button_copy || 'View All Products';
    this.viewAllProductsButtonTextColor =
      data?.end_show_view_all_products_button_text_color || colors.white;
    this.showCompareProductsButton =
      data?.end_show_compare_products_button || false;
    this.compareProductsButtonColor =
      data?.end_show_compare_products_button_color || colors.green4;
    this.compareProductsButtonText =
      data?.end_show_compare_products_button_copy || 'Compare Products';
    this.compareProductsButtonTextColor =
      data?.end_show_compare_products_button_text_color || colors.white;
    this.compareProductsUrl =
      data?.product_demo_locale?.compare_products_link || '';
    this.showTakeQuizButton = data?.end_show_take_quiz_button || false;
    this.takeQuizButtonColor =
      data?.end_show_take_quiz_button_color || colors.green4;
    this.takeQuizButtonText =
      data?.end_show_take_quiz_button_copy || 'Take the Quiz';
    this.takeQuizButtonTextColor =
      data?.end_show_take_quiz_button_text_color || colors.white;
    this.takeQuizUrl = data?.product_demo_locale?.quiz_link || '';
    this.productName = data?.start_screen_subtitle || 'na';
  }

  props = () => ({
    title: this.title,
    titleColor: this.titleColor,
    titleFont: this.titleFont,
    bgColor: this.bgColor,
    bgImage: this.bgImage,
    showViewAllProductsButton: this.showViewAllProductsButton,
    viewAllProductsButtonColor: this.viewAllProductsButtonColor,
    viewAllProductsButtonText: this.viewAllProductsButtonText,
    viewAllProductsButtonTextColor: this.viewAllProductsButtonTextColor,
    showCompareProductsButton: this.showCompareProductsButton,
    compareProductsButtonColor: this.compareProductsButtonColor,
    compareProductsButtonText: this.compareProductsButtonText,
    compareProductsButtonTextColor: this.compareProductsButtonTextColor,
    compareProductsUrl: this.compareProductsUrl,
    showTakeQuizButton: this.showTakeQuizButton,
    takeQuizButtonColor: this.takeQuizButtonColor,
    takeQuizButtonText: this.takeQuizButtonText,
    takeQuizButtonTextColor: this.takeQuizButtonTextColor,
    takeQuizUrl: this.takeQuizUrl,
    productName: this.productName,
  });
}
