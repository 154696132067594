import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles/variables';

export default function Footer({ logoUrl, logoAlt, bgColor }) {
  return (
    <Container bgColor={bgColor}>
      {logoUrl && <Logo src={logoUrl} alt={logoAlt} />}
    </Container>
  );
}

const Container = styled.footer`
  background-color: ${({ bgColor }) => bgColor || colors.gray6};
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(68)}px` : 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  display: block;
  height: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(30)}px` : 'auto'}; ;
`;

Footer.propTypes = {
  logoUrl: PropTypes.string,
  logoAlt: PropTypes.string,
  bgColor: PropTypes.string,
};

Footer.defaultProps = {
  logoUrl: '',
  logoAlt: '',
  bgColor: '',
};
