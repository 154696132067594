export const fontVariables = {
  families: {
    primaryFont: 'HarmoniaSans-Condensed',
    secondaryFont: 'HarmoniaSans',
  },
  weights: {
    normal: 400,
    semibold: 600,
    bold: 700,
    black: 900,
  },
};

export const colors = {
  white: '#fff',
  grey1: '#6a768c',
  grey2: '#506b81',
  grey3: '#3b3b3b',
  gray4: '#f2f4f7',
  gray5: '#545f74',
  gray6: '#fafafa',
  green1: '#518a70',
  green2: '#61af5f',
  green3: '#6cb86a',
  green4: '#447355',
  green5: '#dce9d5',
  green6: '#48795D',
  green7: '#508A6F',
  green8: '#56946F',
  green9: '#5C9D6D',
  green10: '#60A56C',
  green11: '#63A96C',
};

export default {
  fontVariables,
  colors,
};
