import styled from 'styled-components';

const StackSpacing = [0, 12, 24, 36, 48];

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ spacing = 0 }) => `${StackSpacing[spacing]}px`};
`;

export default Stack;
