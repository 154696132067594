import PropTypes from 'prop-types';

export const propTypes = {
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  modelName: PropTypes.string,
  subtitleColor: PropTypes.string,
  subtitleFont: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  featuresModelNameColor: PropTypes.string,
  featuresDescriptionColor: PropTypes.string,
  bgColor: PropTypes.string,
  accentColor: PropTypes.string,
  featuresTitleColor: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon_url: PropTypes.string,
      icon_alt_txt: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      legal_footnote: PropTypes.string,
    })
  ),
  imagesBaseUrl: PropTypes.string,
};

export const defaultProps = {
  logo: '',
  logoAlt: '',
  modelName: '',
  subtitleColor: '',
  subtitleFont: '',
  title: '',
  titleColor: '',
  titleFont: '',
  featuresModelNameColor: '',
  featuresDescriptionColor: '',
  bgColor: '',
  accentColor: '',
  featuresTitleColor: '',
  features: null,
  imagesBaseUrl: '',
};
