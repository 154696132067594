import React from 'react';
import styled from 'styled-components';
import { colors, fontVariables } from '../../../styles/variables';
import { ProductCardType } from '../../../types';

const ProductCard = ({
  logoUrl,
  logoUrlAlt,
  header,
  headerColor,
  title,
  titleColor,
  bgColor,
  bgImage,
  prodImage,
  prodImageAlt,
  seriesTitle,
  seriesTitleTextColor,
  seriesDescription,
  seriesDescriptionTextColor,
  exploreUrl,
  exploreText,
  exploreButtonColor,
  exploreButtonTextColor,
}) => (
  <Container>
    <Header bgColor={bgColor} bgImage={bgImage}>
      {logoUrl ? <Logo src={logoUrl} alt={logoUrlAlt} /> : <LogoPlaceholder />}
      <Category color={headerColor}>{header}</Category>
      <Title color={titleColor}>{title}</Title>
      <ProductImage src={prodImage} alt={prodImageAlt} />
    </Header>
    <Content>
      <ExploreLink
        bgColor={exploreButtonColor}
        color={exploreButtonTextColor}
        href={exploreUrl}
        target="_blank"
        rel="noreferrer"
      >
        {exploreText}
      </ExploreLink>
      <SeriesTitle color={seriesTitleTextColor}>{seriesTitle}</SeriesTitle>
      <SeriesDescription color={seriesDescriptionTextColor}>
        {seriesDescription}
      </SeriesDescription>
    </Content>
  </Container>
);
const Container = styled.div`
  width: ${({ theme }) => theme.mobileRT.getWidthValue(255)}px;
  flex: 0 0 auto;
`;

const Header = styled.header`
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(25)}px;
  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-repeat: no-repeat;
  background-position: center center;
  height: ${({ theme }) => theme.mobileRT.getWidthValue(406)}px;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  display: block;
  height: ${({ theme }) => theme.mobileRT.getWidthValue(36)}px;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(36)}px;
  margin: 0 auto ${({ theme }) => theme.mobileRT.getWidthValue(26)}px;
`;

const LogoPlaceholder = styled.div`
  height: ${({ theme }) => theme.mobileRT.getWidthValue(36)}px;
  min-height: ${({ theme }) => theme.mobileRT.getWidthValue(36)}px;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(36)}px;
  margin: 0 auto ${({ theme }) => theme.mobileRT.getWidthValue(26)}px;
`;

const Category = styled.p`
  font-family: ${fontVariables.families.primaryFont};
  font-weight: ${fontVariables.weights.black};
  font-size: ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  line-height: 1;
  min-height: ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  color: ${({ color }) => color};
  margin: auto 0 ${({ theme }) => theme.mobileRT.getWidthValue(28)}px;
  text-align: center;
`;

const Title = styled.h2`
  font-family: ${fontVariables.families.primaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getWidthValue(51)}px;
  color: ${({ color }) => color};
  line-height: normal;
  font-weight: ${fontVariables.weights.normal};
  text-align: center;
  margin: 0;
  padding: 0;
`;

const ProductImage = styled.img`
  display: block;
  margin-bottom: 0;
  margin-top: 0;
`;

const Content = styled.div`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(7)}px;
  transform: translateY(${({ theme }) => theme.mobileRT.getWidthValue(-23)}px);
`;

const ExploreLink = styled.a`
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  font-family: ${fontVariables.families.secondaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(17)}px;
  line-height: 1;
  border-radius: ${({ theme }) => theme.mobileRT.getWidthValue(12)}px;
  background-color: ${({ bgColor }) => bgColor || colors.grey1};
  color: ${({ color }) => color || colors.white};
  text-align: center;
  margin: 0 auto;
  text-transform: capitalize;
  font-weight: ${fontVariables.weights.semibold};
  padding: ${({ theme }) => theme.mobileRT.getWidthValue(16)}px
    ${({ theme }) => theme.mobileRT.getWidthValue(26)}px;
  margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(26)}px;
`;

const SeriesTitle = styled.h3`
  margin: 0;
  font-family: ${fontVariables.families.primaryFont};
  color: ${({ color }) => color || colors.green4};
  text-transform: capitalize;
  letter-spacing: 0.16px;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(16)}px;
  font-weight: ${fontVariables.weights.bold};
  line-height: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
  margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(10)}px;
`;

const SeriesDescription = styled.p`
  margin: 0 auto;
  font-family: ${fontVariables.families.primaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(20)}px;
  line-height: ${({ theme }) => theme.mobileRT.getWidthValue(24)}px;
  color: ${({ color }) => color || colors.green4};
  text-align: center;
  max-width: ${({ theme }) => theme.mobileRT.getWidthValue(150)}px;
`;

ProductCard.propTypes = ProductCardType;

ProductCard.defaultProps = {
  logoUrl: '',
  header: '',
  headerColor: '',
  title: '',
  titleColor: '',
  bgColor: '',
  bgImage: '',
  prodImage: '',
  seriesTitle: '',
  seriesDescription: '',
  exploreUrl: '',
  exploreText: '',
};

export default ProductCard;
