import styled from 'styled-components';
import device from '../../../utils/responsive/device';

const NavButton = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: none;
  appearance: none;
  border-radius: 50%;

  svg {
    width: 48px;
    height: 48px;
  }

  g,
  line,
  path {
    stroke: ${({ color }) => color};
  }

  @media ${device.tablet} {
    svg {
      width: 60px;
      height: 60px;
    }
  }

  @media ${device.xlarge} {
    svg {
      width: 96px;
      height: 96px;
    }
  }
`;

export default NavButton;
