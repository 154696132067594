/* eslint-disable no-nested-ternary */
import { SLIDE_CATEGORIES } from '../data/constants';
import SlideModel from './SlideModel';

export default class CarouselModel {
  constructor(carouselData = {}) {
    this.title = carouselData?.carousel_header || '';
    this.titleColor = carouselData?.carousel_header_text_color || '';
    this.titleFont = carouselData?.carousel_header_font || '';
    this.bgColor = carouselData?.carousel_header_background_color || '';
    this.bgImage = carouselData?.carousel_desktop_background_image_url || '';
    this.bgImageAlt =
      carouselData?.carousel_desktop_background_image_alt_txt || '';
    this.bgImageMb =
      carouselData.carousel_mobile_background_image_url ||
      this.carousel_desktop_background_image_url;
    this.navBgColor = carouselData?.navigation_bar_background_color;
    this.navSelectedColor = carouselData?.navigation_bar_selected_state_color;
    this.navTextColor = carouselData?.navigation_bar_text_color;
    this.navItems = [];
    this.slides =
      carouselData?.carousel_categories?.length > 0
        ? this.parseSlides(carouselData?.carousel_categories)
        : [];
    this.mediaUrls = this.slides.map(slide => slide.mediaUrl);
  }

  parseSlidesOld = (slides = []) =>
    Object.values(SLIDE_CATEGORIES)
      .map(category => {
        let groupedArr = slides.filter(slide => slide.category === category);
        groupedArr = groupedArr.map(
          (slide, index) =>
            new SlideModel({
              ...slide,
              groupSize: groupedArr.length,
              groupIndex: index,
            }).props
        );
        return groupedArr;
      })
      .flat();

  parseSlides = (categories = []) => {
    let slides = [];
    categories.map(category => {
      const navItem = this.parseNavItem(category);
      const categoryName = this.getCategoryTitle(navItem?.title);
      const categorySize = category?.feature_cards?.length;
      const parsedSlides = category?.feature_cards.map(
        (item, index) =>
          new SlideModel({
            ...item,
            category: categoryName,
            groupIndex: index,
            groupSize: categorySize,
          }).props
      );
      navItem.targetIndex = slides.length;
      slides = [...slides, parsedSlides].flat();
      navItem.endIndex = slides.length - 1;
      this.navItems = [...this.navItems, navItem];
      return true;
    });
    return slides;
  };

  getCategoryTitle = (title = '') => title.replace(' ', '_').toLowerCase();

  parseNavItem = (categoryData = {}) => ({
    uuid: categoryData?.uuid,
    title: categoryData?.title,
    icon: categoryData?.icon_url,
  });

  get props() {
    return {
      title: this.title,
      titleColor: this.titleColor,
      titleFont: this.titleFont,
      bgColor: this.bgColor,
      bgImage: this.bgImage,
      bgImageAlt: this.bgImageAlt,
      bgImageMb: this.bgImageMb,
      slides: this.slides,
      navBgColor: this.navBgColor,
      navSelectedColor: this.navSelectedColor,
      navTextColor: this.navTextColor,
      navItems: this.navItems,
      mediaUrls: this.mediaUrls,
    };
  }

  get titleProps() {
    return {
      title: this.title,
      titleColor: this.titleColor,
      titleFont: this.titleFont,
      bgColor: this.bgColor,
      bgImage: this.bgImage,
      bgImageMb: this.bgImageMb,
    };
  }
}
