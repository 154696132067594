import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontVariables, colors } from '../../../styles/variables';
import { ReactComponent as ArrowDown } from '../../../assets/svgs/circle-arrow-down.svg';
import { ID_TEXTS } from '../../../utils/constants';
import { normalizeScapedLineBreaks } from '../../../utils/string';

const {
  families: { primaryFont },
  weights: { bold, normal },
} = fontVariables;

const Intro = ({
  className,
  logo,
  logoAlt,
  model,
  modelColor,
  modelFont,
  title,
  titleColor,
  titleFont,
  bgColor,
  accentColor,
  narrowTitle,
  onNext,
}) => {
  const isTitleLong = title?.length > 45;

  return (
    <Container
      className={className}
      bgColor={bgColor}
      isTitleLong={isTitleLong}
    >
      <LogoWrapper>
        <Logo src={logo} alt={logoAlt} id={ID_TEXTS.INTRO_LOGO} />
      </LogoWrapper>
      <div id={ID_TEXTS.INTRO_CONTENT}>
        <ModelName color={modelColor} font={modelFont}>
          {model}
        </ModelName>
        <Title
          color={titleColor}
          narrowTitle={narrowTitle}
          isTitleLong={isTitleLong}
          font={titleFont}
        >
          {normalizeScapedLineBreaks(title)}
        </Title>
        <DownArrowWrapper>
          {onNext ? (
            <DownButton onClick={onNext}>
              <DownArrow color={accentColor} />
            </DownButton>
          ) : (
            <DownArrow color={accentColor} />
          )}
        </DownArrowWrapper>
      </div>
    </Container>
  );
};

export const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${colors.white};

  padding-top: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(23)
      : theme.webRT.getWidthValue(67)}px;
  padding-right: ${({ theme, isTitleLong }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(10)
      : theme.webRT.getWidthValue(isTitleLong ? 262 : 272)}px;
  padding-left: ${({ theme, isTitleLong }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(10)
      : theme.webRT.getWidthValue(isTitleLong ? 262 : 272)}px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(16)
      : theme.webRT.getWidthValue(68)}px; ;
`;

export const Logo = styled.img`
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(44)
      : theme.webRT.getWidthValue(77)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(44)
      : theme.webRT.getWidthValue(77)}px;
`;

export const ModelName = styled.h2`
  color: ${({ color }) => color};
  font-family: ${({ font }) => font || primaryFont};
  font-weight: ${bold};
  text-align: center;
  margin: 0;

  letter-spacing: ${({ theme }) => (theme.isMobile ? 0.22 : 0.31)}px;
  line-height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(20)
      : theme.webRT.getWidthValue(20)}px;
  font-size: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getFontSize(22)
      : theme.webRT.getFontSize(31)}px;
  margin-top: ${({ theme }) =>
    theme.isMobile ? `${theme.mobileRT.getWidthValue(16)}px` : '0'};
  margin-bottom: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(24)
      : theme.webRT.getWidthValue(48)}px;
`;

export const Title = styled.h1`
  white-space: ${({ theme }) => (theme.isMobile ? 'normal' : 'pre-wrap')};
  color: ${({ color }) => color};
  font-family: ${({ font }) => font || primaryFont};
  font-weight: ${normal};
  text-align: center;
  margin: ${({ theme, narrowTitle }) =>
    narrowTitle && !theme.isMobile ? '0 auto 0' : 0};
  width: ${({ theme, narrowTitle }) =>
    narrowTitle && !theme.isMobile ? '50%' : 'auto'};

  letter-spacing: ${({ theme }) => (theme.isMobile ? 0.58 : 1.6)}px;
  line-height: ${({ theme, isTitleLong }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(64)
      : theme.webRT.getWidthValue(isTitleLong ? 110 : 135)}px;
  font-size: ${({ theme, isTitleLong }) =>
    theme.isMobile
      ? theme.mobileRT.getFontSize(58)
      : theme.webRT.getFontSize(isTitleLong ? 110 : 135)}px;
  margin-bottom: ${({ theme, isTitleLong }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(24)
      : theme.webRT.getWidthValue(isTitleLong ? 20 : 80)}px;
`;

export const DownArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DownArrow = styled(ArrowDown)`
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(31)
      : theme.webRT.getWidthValue(46)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(31)
      : theme.webRT.getWidthValue(46)}px;

  g,
  line,
  path {
    stroke: ${({ color }) => color};
  }
`;

export const DownButton = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: none;
  appearance: none;

  ${DownArrow} {
    width: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(46)
        : theme.webRT.getWidthValue(46)}px;
    height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(46)
        : theme.webRT.getWidthValue(46)}px;
  }
`;

Intro.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  model: PropTypes.string,
  modelColor: PropTypes.string,
  modelFont: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  bgColor: PropTypes.string,
  accentColor: PropTypes.string,
  narrowTitle: PropTypes.bool,
  onNext: PropTypes.func,
};

Intro.defaultProps = {
  className: '',
  logo: '',
  logoAlt: '',
  model: '',
  modelColor: '',
  modelFont: '',
  title: '',
  titleColor: '',
  titleFont: '',
  bgColor: '',
  accentColor: '',
  narrowTitle: false,
  onNext: null,
};

export default Intro;
