import { gsap } from 'gsap';
import { ID_TEXTS, TOTAL_360_IMGS } from './constants';
import { DEFAULT_360_IMAGE_SIZE } from './image';

export function handleEndFeatureAnimation(scrollToRef, handleComplete) {
  let scroll = scrollToRef.current;
  if (scroll != null) {
    scroll.kill();
  }

  scroll = gsap.to(window, {
    duration: 1,
    scrollTo: `#${ID_TEXTS.MAIN_CONTENT_REF}`,
    onComplete: () => {
      handleComplete();
    },
  });
}

export function handleNextFeatureAnimation(
  label,
  duration = 3,
  scrollToRef,
  mainTimeLineRef,
  scrollTriggerRef
) {
  let scrollTo = scrollToRef.current;
  const mainTimeline = mainTimeLineRef.current;
  const scrollTrigger = scrollTriggerRef.current;
  if (mainTimeline && scrollTrigger) {
    const targetLabel = mainTimeline.labels[label];
    if (targetLabel) {
      const percent = targetLabel / mainTimeline.totalDuration();
      const scrollPos =
        scrollTrigger.start +
        (scrollTrigger.end - scrollTrigger.start) * percent;
      if (scrollTo) {
        scrollTo.kill();
      }
      scrollTo = gsap.to(window, {
        duration,
        scrollTo: scrollPos,
        onComplete: () => {
          scrollTo = null;
        },
      });
    }
  }
}

export function imageUrlsGenerator(imageName = '', format = 'png') {
  return Array.from({ length: TOTAL_360_IMGS }).map(
    (_, index) =>
      `https://app.irobot.mdsrv.media/${DEFAULT_360_IMAGE_SIZE}/public-assets/product-demo-v2/intros/${imageName}${
        (index || 1) * 10
      }.${format}`
  );
}

export function timelineGenerator(featureOrder) {
  const mainTimeLine = gsap.timeline();

  Array.from({ length: 5 }).forEach((_, index, arr) => {
    const featureStep = featureOrder[index + 1];
    mainTimeLine.addLabel(`step${index + 1}`);
    mainTimeLine.add(featureStep?.animation, featureStep?.time);

    if (index + 1 === arr.length) {
      mainTimeLine.set({}, {}, '+=0.5');
      mainTimeLine.addLabel(`step${index + 1}`);
    }
  });
  return mainTimeLine;
}
