import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontVariables } from '../../../styles/variables';
import analytics from '../../../analytics';

const {
  families: { primaryFont, secondaryFont },
  weights: { bold, semibold },
} = fontVariables;

const StillNotSure = ({
  title,
  bgColor,
  titleColor,
  bgImage,
  titleFont,
  showViewAllProductsButton,
  viewAllProductsButtonColor,
  viewAllProductsButtonText,
  viewAllProductsButtonTextColor,
  showCompareProductsButton,
  compareProductsButtonColor,
  compareProductsButtonText,
  compareProductsButtonTextColor,
  showTakeQuizButton,
  takeQuizButtonColor,
  takeQuizButtonText,
  takeQuizButtonTextColor,
  onViewAllClick,
  compareProductsUrl,
  takeQuizUrl,
  productName,
  showBg,
  showViewAll,
}) => {
  const handleViewAllClick = () => {
    onViewAllClick();
    analytics.trackButtonClick('View all Products', productName);
  };

  return (
    <Container bgColor={bgColor} bgImage={bgImage} showBg={showBg}>
      <Title color={titleColor} font={titleFont}>
        {title}
      </Title>
      {showViewAll && showViewAllProductsButton && (
        <ButtonsContainer
          bgColor={viewAllProductsButtonColor}
          color={viewAllProductsButtonTextColor}
        >
          <Pill type="button" onClick={handleViewAllClick}>
            {viewAllProductsButtonText}
          </Pill>
        </ButtonsContainer>
      )}
      {showCompareProductsButton && compareProductsUrl && (
        <ButtonsContainer
          bgColor={compareProductsButtonColor}
          color={compareProductsButtonTextColor}
        >
          <Pill
            as="a"
            href={compareProductsUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              analytics.trackButtonClick(
                compareProductsButtonText,
                productName
              );
            }}
          >
            {compareProductsButtonText}
          </Pill>
        </ButtonsContainer>
      )}
      {showTakeQuizButton && takeQuizUrl && (
        <ButtonsContainer
          bgColor={takeQuizButtonColor}
          color={takeQuizButtonTextColor}
        >
          <Pill
            as="a"
            href={takeQuizUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              analytics.trackButtonClick(takeQuizButtonText, productName);
            }}
          >
            {takeQuizButtonText}
          </Pill>
        </ButtonsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  ${({ bgImage, showBg }) =>
    showBg && bgImage && `background-image: url(${bgImage})`};
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;
  padding-top: ${({ theme }) => theme.mobileRT.getWidthValue(53)}px;
  padding-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(53)}px;
  padding-left: ${({ theme }) => theme.mobileRT.getWidthValue(24)}px;
  padding-right: ${({ theme }) => theme.mobileRT.getWidthValue(24)}px;
  min-height: ${({ theme, showBg }) =>
    showBg ? theme.mobileRT.getWidthValue(295) : 0}px;
`;

const Title = styled.h2`
  color: ${({ color }) => color};
  font-weight: ${bold};
  font-family: ${({ font }) => font || primaryFont};
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(32)}px;
  line-height: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(38)}px;
  text-align: center;
`;

const Pill = styled.button`
  border: none;
  font-size: ${({ theme }) => theme.mobileRT.getFontSize(17)}px;
  font-family: ${secondaryFont};
  font-weight: ${semibold};
  text-transform: capitalize;
  letter-spacing: 0.17px;
  line-height: ${({ theme }) => theme.mobileRT.getWidthValue(20)}px;
  display: block;
  text-align: center;
  padding: ${({ theme }) => theme.mobileRT.getWidthValue(12)}px;
  border-radius: ${({ theme }) => theme.mobileRT.getWidthValue(23)}px;
  text-decoration: none;
  flex: 0 0 auto;
  box-sizing: border-box;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.mobileRT.getWidthValue(230)}px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.mobileRT.getWidthValue(14)}px;
  & ${Pill} {
    color: ${({ color }) => color};
    background-color: ${({ bgColor }) => bgColor};
  }
`;

StillNotSure.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  bgImage: PropTypes.string,
  titleFont: PropTypes.string,
  showViewAllProductsButton: PropTypes.bool,
  viewAllProductsButtonColor: PropTypes.string,
  viewAllProductsButtonText: PropTypes.string,
  viewAllProductsButtonTextColor: PropTypes.string,
  showCompareProductsButton: PropTypes.bool,
  compareProductsButtonColor: PropTypes.string,
  compareProductsButtonText: PropTypes.string,
  compareProductsButtonTextColor: PropTypes.string,
  showTakeQuizButton: PropTypes.bool,
  takeQuizButtonColor: PropTypes.string,
  takeQuizButtonText: PropTypes.string,
  takeQuizButtonTextColor: PropTypes.string,
  compareProductsUrl: PropTypes.string,
  takeQuizUrl: PropTypes.string,
  showBg: PropTypes.bool,
  onViewAllClick: PropTypes.func,
  productName: PropTypes.string,
  showViewAll: PropTypes.bool,
};

StillNotSure.defaultProps = {
  title: '',
  bgColor: '',
  titleColor: colors.green4,
  bgImage: '',
  titleFont: '',
  showViewAllProductsButton: false,
  viewAllProductsButtonColor: '',
  viewAllProductsButtonText: '',
  viewAllProductsButtonTextColor: '',
  showCompareProductsButton: false,
  compareProductsButtonColor: '',
  compareProductsButtonText: '',
  compareProductsButtonTextColor: '',
  showTakeQuizButton: false,
  takeQuizButtonColor: '',
  takeQuizButtonText: '',
  takeQuizButtonTextColor: '',
  compareProductsUrl: '',
  takeQuizUrl: '',
  showBg: true,
  onViewAllClick: () => {},
  productName: '',
  showViewAll: true,
};

export default StillNotSure;
