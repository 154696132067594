import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ScrollContainer from './common/ScrollContainer';
import StartScreen, { StartScreenContainer } from './common/StartScreen';
import { defaultProps, propTypes } from '../ModelDetail/propsFile';
import { ID_TEXTS, PRELOADING_THRESHOLD } from '../../utils/constants';
import {
  createFadeOutColorLine,
  createImageRotationTimeline,
  createShowFeatureTimeline,
  fadeInFromRight,
  hideSideImage,
} from './common/timeline';
import Feature, {
  FeatureStack,
  FeatureStackCentered,
  FeatureContainer,
  FeatureItem,
  FeatureBottom,
  FeatureSideStack,
  FeatureItemCentered,
} from './common/Feature';
import Image, {
  ImageBaseContainer,
  ImageContainer,
  ImageController,
  ImageWrapper,
} from './common/Image';
import {
  handleEndFeatureAnimation,
  handleNextFeatureAnimation,
  imageUrlsGenerator,
  timelineGenerator,
} from '../../utils/helper';
import { useGetClientHeight } from '../../hooks/useGetClientHeight';

const imageUrls = imageUrlsGenerator(
  'rc-essential-black/Roomba_Combo_Essential_Black_360images_transparent_'
);

const imageBaseUrl =
  'https://app.irobot.mdsrv.media/public-assets/product-demo-v2/intros/rc-essential-black/OPP_BLACK_perspective_underneath2.png';

const imageFront =
  'https://app.irobot.mdsrv.media/cdn-cgi/image/width=860,quality=75,format=webp/public-assets/product-demo-v2/intros/rc-essential-black/front_black.png';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default function TemplateOPPCombo({
  bgColor,
  logo,
  logoAlt,
  modelName,
  subtitleFont,
  subtitleColor,
  title,
  titleFont,
  titleColor,
  accentColor,
  featuresTitleColor,
  featuresModelNameColor,
  featuresDescriptionColor,
  features,
}) {
  const [imageHeight, setImageHeight] = useState(0);

  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const containerHeight = useGetClientHeight();

  const scrollContainerRef = useRef(null);
  const mainTimeLineRef = useRef(null);
  const scrollTriggerRef = useRef(null);
  const scrollToRef = useRef(null);

  function handleImageLoad(e) {
    setImageHeight(e.target.clientHeight);
    setLoadedImages(counter => counter + 1);
  }

  function handleNext(label, duration = 3) {
    handleNextFeatureAnimation(
      label,
      duration,
      scrollToRef,
      mainTimeLineRef,
      scrollTriggerRef
    );
  }

  function handleEnd() {
    handleEndFeatureAnimation(scrollToRef, () => {
      scrollToRef.current = null;
    });
  }

  useEffect(() => {
    if (loadedImages >= PRELOADING_THRESHOLD) {
      setLoading(false);
    }
  }, [loadedImages]);

  useEffect(() => {
    const element = scrollContainerRef.current;
    if (!loading && element && imageHeight > 0) {
      const startScreenTimeline = gsap
        .timeline()
        .to(element.querySelector(`#${ID_TEXTS.INTRO_CONTENT}`), {
          opacity: 0,
          zIndex: -1,
        });
      const rotateStartImage = createImageRotationTimeline({
        element,
        startFrame: 1,
        endFrame: 20,
        attributes: {
          top: containerHeight / 2 - imageHeight * 1.3,
          x: containerHeight / 2 - imageHeight * 0.9,
          scale: 1.8,
          transformOrigin: 'center top',
        },
        onUpdate: index => setCurrentImageIndex(index),
      });
      const showStartFeature = createShowFeatureTimeline({
        element,
        nextFeature: `#${ID_TEXTS.START_CONTENT}`,
      });
      startScreenTimeline.add(rotateStartImage, 0).add(showStartFeature, 0);

      const createFeatureOneTimeline = () => {
        const fadeOutColorLine = createFadeOutColorLine({ element });
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.START_CONTENT}`,
          nextFeature: `#${ID_TEXTS.FEATURE_1}`,
        });
        const fadeIn = gsap.timeline().to(
          element.querySelector(`#${ID_TEXTS.IMAGE_FADE_RIGHT_1}`),
          {
            left: '35%',
            opacity: 1,
            scale: 1.1,
          },
          0
        );
        const translateMainImage = gsap
          .timeline()
          .to(element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`), {
            y: '-100%',
            x: 0,
            scale: 2,
            transformOrigin: 'center top',
          });
        return gsap
          .timeline()
          .add(fadeOutColorLine)
          .add(showFeature, 0)
          .add(fadeIn, 0)
          .add(translateMainImage, 0);
      };

      const createFeatureTwoTimeline = () => {
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.FEATURE_1}`,
          nextFeature: `#${ID_TEXTS.FEATURE_2}`,
        });
        const hideSideImg = hideSideImage({
          element,
          image: ID_TEXTS.IMAGE_FADE_RIGHT_1,
        });
        const rotateImage = createImageRotationTimeline({
          element,
          startFrame: 1,
          endFrame: 25,
          attributes: {
            y: containerHeight / 2 - imageHeight * 1.1,
            scale: 2.5,
            transformOrigin: 'center top',
          },
          onUpdate: index => setCurrentImageIndex(index),
        });

        return gsap
          .timeline()
          .add(rotateImage, 0)
          .add(showFeature, 0)
          .add(hideSideImg, 0);
      };

      const createFeatureThreeTimeline = () => {
        const showFeature = createShowFeatureTimeline({
          element,
          prevFeature: `#${ID_TEXTS.FEATURE_2}`,
          nextFeature: `#${ID_TEXTS.FEATURE_3}`,
        });
        const translateImage = gsap
          .timeline()
          .to(element.querySelector(`#${ID_TEXTS.IMAGES_CONTAINER}`), {
            y: '-150%',
            x: 0,
            scale: 2,
            transformOrigin: 'center top',
          });
        const fadeIn = fadeInFromRight({ element });
        return gsap
          .timeline()
          .add(translateImage)
          .add(showFeature, 0)
          .add(fadeIn, 0);
      };

      const featureOrder = {
        1: { animation: startScreenTimeline, time: '+=0.5' },
        2: { animation: createFeatureOneTimeline(), time: '+=0.5' },
        3: { animation: createFeatureTwoTimeline(), time: '+=0.5' },
        4: { animation: createFeatureThreeTimeline(), time: '+=0.5' },
      };

      const mainTimeLine = timelineGenerator(featureOrder);

      const scrollTrigger = ScrollTrigger.create({
        animation: mainTimeLine,
        trigger: element,
        start: 'top top',
        end: '+=600%',
        scrub: true,
        pin: true,
      });

      mainTimeLineRef.current = mainTimeLine;
      scrollTriggerRef.current = scrollTrigger;
    }
  }, [containerHeight, imageHeight, loading]);

  return (
    <ScrollContainer bgColor={bgColor} ref={scrollContainerRef}>
      {loading || containerHeight === 0 ? <Loader /> : null}
      {containerHeight > 0 ? (
        <StartScreenContainer
          id={ID_TEXTS.INTRO_CONTENT}
          height={Math.ceil(containerHeight * 0.7)}
        >
          <StartScreen
            logo={logo}
            logoAlt={logoAlt}
            model={modelName}
            modelFont={subtitleFont}
            modelColor={subtitleColor}
            title={title}
            titleFont={titleFont}
            titleColor={titleColor}
            accentColor={accentColor}
            height={Math.ceil(containerHeight * 0.7)}
            onNext={() => handleNext('step2', 2)}
          />
        </StartScreenContainer>
      ) : null}
      <ImageContainer id={ID_TEXTS.IMAGES_CONTAINER} height={containerHeight}>
        <ImageController height={Math.ceil(containerHeight * 0.3 * 2)}>
          {imageUrls.map((src, index) => (
            <ImageWrapper key={`oppcombo-image-${index + 1}`}>
              <Image
                src={src}
                alt={`oppcombo 360-image frame ${index + 1}`}
                active={index + 1 === currentImageIndex}
                onLoad={handleImageLoad}
              />
            </ImageWrapper>
          ))}
        </ImageController>
      </ImageContainer>
      <ImageBaseContainer
        id={ID_TEXTS.IMAGE_BASE_CONTAINER}
        height={containerHeight}
      >
        <ImageController height={Math.ceil(containerHeight * 0.3 * 2)}>
          <ImageWrapper>
            <Image src={imageBaseUrl} alt="OPP Combo Base" active />
          </ImageWrapper>
        </ImageController>
      </ImageBaseContainer>
      <FeatureContainer>
        <FeatureStack id={ID_TEXTS.START_CONTENT}>
          <FeatureItem />
          <FeatureItem>
            <FeatureBottom
              icon={features[0]?.icon_url}
              iconAlt={features[0]?.icon_alt_txt}
              model={features[0]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[0]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[0]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[0]?.legal_footnote}
              onNext={() => handleNext('step3', 1)}
              overlay
            />
          </FeatureItem>
        </FeatureStack>
        <FeatureStack id={ID_TEXTS.FEATURE_1}>
          <FeatureItem />
          <FeatureItem>
            <FeatureBottom
              icon={features[1]?.icon_url}
              iconAlt={features[1]?.icon_alt_txt}
              model={features[1]?.subtitle}
              modelColor={featuresModelNameColor}
              modelFont={subtitleFont}
              title={features[1]?.title}
              titleColor={featuresTitleColor}
              titleFont={titleFont}
              description={features[1]?.description}
              descriptionColor={featuresDescriptionColor}
              accentColor={accentColor}
              footnote={features[1]?.legal_footnote}
              onNext={() => handleNext('step4', 1)}
              overlay
            />
          </FeatureItem>
        </FeatureStack>
        <FeatureSideStack
          id={ID_TEXTS.IMAGE_FADE_RIGHT_1}
          justify="flex-start"
          padding="100px 0"
        >
          <Image active src={imageBaseUrl} />
        </FeatureSideStack>
        <FeatureStackCentered id={ID_TEXTS.FEATURE_2}>
          <Feature
            icon={features[2]?.icon_url}
            iconAlt={features[2]?.icon_alt_txt}
            model={features[2]?.subtitle}
            modelColor={featuresModelNameColor}
            modelFont={subtitleFont}
            title={features[2]?.title}
            titleColor={featuresTitleColor}
            titleFont={titleFont}
            description={features[2]?.description}
            descriptionColor={featuresDescriptionColor}
            accentColor={accentColor}
            footnote={features[2]?.legal_footnote}
            overlay
            onNext={() => handleNext('step5', 1)}
          />
        </FeatureStackCentered>
        <FeatureStackCentered id={ID_TEXTS.FEATURE_3}>
          <FeatureItem />
          <FeatureBottom
            icon={features[3]?.icon_url}
            iconAlt={features[3]?.icon_alt_txt}
            model={features[3]?.subtitle}
            modelColor={featuresModelNameColor}
            modelFont={subtitleFont}
            title={features[3]?.title}
            titleColor={featuresTitleColor}
            titleFont={titleFont}
            description={features[3]?.description}
            descriptionColor={featuresDescriptionColor}
            accentColor={accentColor}
            footnote={features[3]?.legal_footnote}
            overlay
            onNext={() => handleEnd()}
          />
        </FeatureStackCentered>
        <FeatureSideStack id={ID_TEXTS.IMAGE_FADE_RIGHT_2}>
          <FeatureItemCentered>
            <FeatureItemCentered />
            <FeatureItemCentered>
              <Image active src={imageFront} />
            </FeatureItemCentered>
          </FeatureItemCentered>
          <FeatureItemCentered />
        </FeatureSideStack>
      </FeatureContainer>
    </ScrollContainer>
  );
}

TemplateOPPCombo.propTypes = propTypes;
TemplateOPPCombo.defaultProps = defaultProps;

const Loader = styled.div``;
