import React, { useState, useEffect } from 'react';
import { useDemoData } from '../../../hooks';
import CarouselNav from '../../../models/CarouselNavigationModel';

export const connect = WrappedComponent => props => {
  const [currentNavdata, setCurrentNavdata] = useState({});
  const demoData = useDemoData();

  const { carousel, showViewAll, productName } = demoData;

  useEffect(() => {
    if (carousel?.navigation)
      setCurrentNavdata(
        new CarouselNav({
          ...carousel?.navigation,
          showViewAll,
          productName,
        }).props
      );
    return () => {};
  }, [carousel, productName, showViewAll]);

  return <WrappedComponent {...currentNavdata} {...props} />;
};

export default connect;
