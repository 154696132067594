import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { extractUrlMetadata } from '../../Image';

const SlideVideo = ({
  isActive,
  isCurrent,
  mediaUrl,
  thumbUrl,
  altText,
  groupIndex,
  groupSize,
  isVisible,
}) => {
  const videoRef = useRef(null);

  const posterUrl = useMemo(() => {
    const { domain, path } = extractUrlMetadata(thumbUrl);
    if (domain && path) {
      return `https://${domain}/cdn-cgi/image/width=${580},quality=75,format=webp${path}`;
    }
    return thumbUrl;
  }, [thumbUrl]);

  useEffect(() => {
    if (videoRef.current) {
      if (isCurrent && isActive && isVisible) {
        videoRef.current.play();
      } else if (
        videoRef.current.currentTime > 0 &&
        !videoRef.current.paused &&
        !videoRef.current.ended &&
        videoRef.current.readyState > 2
      ) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isActive, isCurrent, isVisible]);

  return (
    <Container>
      <SlideIndexIndicator>
        <ul>
          {Array.from(Array(groupSize)).map((_groupEl, index) => (
            <SlideIndexItem
              active={index === groupIndex}
              // eslint-disable-next-line react/no-array-index-key
              key={`index-item-${index}-of-${groupSize}`}
            >
              {index === groupIndex && (
                <span>{`${index} of ${groupSize}`}</span>
              )}
            </SlideIndexItem>
          ))}
        </ul>
      </SlideIndexIndicator>
      <SlideContent poster={posterUrl} alt={altText} ref={videoRef} muted loop>
        <source src={mediaUrl} type="video/mp4" />
      </SlideContent>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const SlideContent = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
`;

const SlideIndexIndicator = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  ul {
    margin: 0;
    display: flex;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: rgba(59, 59, 59, 0.8);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    height: ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(30)
        : theme.webRT.getWidthValue(38)}px;
    justify-content: center;
    align-items: center;
    padding: 0
      ${({ theme }) =>
        theme.isMobile
          ? theme.mobileRT.getWidthValue(13)
          : theme.webRT.getWidthValue(16)}px;
  }
`;

const SlideIndexItem = styled.li`
  display: block;
  border: solid 1px white;
  border-radius: 50%;
  width: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(6)
      : theme.webRT.getWidthValue(10)}px;
  height: ${({ theme }) =>
    theme.isMobile
      ? theme.mobileRT.getWidthValue(6)
      : theme.webRT.getWidthValue(10)}px;
  flex: 0 0 auto;
  background-color: ${({ active }) => (active ? 'white' : 'transparent')};
  margin: 0
    ${({ theme }) =>
      theme.isMobile
        ? theme.mobileRT.getWidthValue(6)
        : theme.webRT.getWidthValue(14)}px;
  span {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

SlideVideo.propTypes = {
  isActive: PropTypes.bool,
  isCurrent: PropTypes.bool,
  mediaUrl: PropTypes.string,
  altText: PropTypes.string,
  thumbUrl: PropTypes.string,
  groupIndex: PropTypes.number,
  groupSize: PropTypes.number,
  isVisible: PropTypes.bool,
};

SlideVideo.defaultProps = {
  isActive: false,
  isCurrent: false,
  mediaUrl: '',
  altText: '',
  thumbUrl: '',
  groupIndex: 0,
  groupSize: 0,
  isVisible: false,
};

export default SlideVideo;
