import { colors } from '../styles/variables';

export default class ProductCardModel {
  constructor(productCardData = {}) {
    this.uuid = productCardData?.uuid;
    this.logoUrl = productCardData?.logo_url;
    this.logoUrlAlt = productCardData?.logo_alt_tag || '';
    this.header = productCardData?.header;
    this.headerColor = productCardData?.header_text_color || colors.white;
    this.title = productCardData?.title || 'm6';
    this.titleColor = productCardData?.title_text_color || colors.white;
    this.bgColor = productCardData?.background_color || colors.green4;
    this.bgImage = productCardData?.background_image_url;
    this.bgImageAlt = productCardData?.background_image_alt_tag || '';
    this.prodImage =
      productCardData?.product_image_url ||
      'https://app.irobot.mdsrv.media/public-assets/product-demo-v2/images/products/m6/all-prods_m6.png';
    this.prodImageAlt = productCardData?.product_image_alt_tag || '';
    this.seriesTitle = productCardData?.subtitle || 'Mop series';
    this.seriesTitleTextColor = productCardData?.subtitle_text_color || '';
    this.seriesDescription =
      productCardData?.description || 'The ultimate robot mop.';
    this.seriesDescriptionTextColor =
      productCardData?.description_text_color || '';
    this.exploreUrl = productCardData?.product_demo_url || '#';
    this.exploreText = productCardData?.menu_button_copy || 'Explore';
    this.exploreButtonColor =
      productCardData?.menu_button_background_color || '';
    this.exploreButtonTextColor = productCardData?.menu_button_text_color || '';
  }

  get props() {
    return {
      uuid: this.uuid,
      logoUrl: this.logoUrl,
      logoUrlAlt: this.logoUrlAlt,
      header: this.header,
      headerColor: this.headerColor,
      title: this.title,
      titleColor: this.titleColor,
      bgColor: this.bgColor,
      bgImage: this.bgImage,
      bgImageAlt: this.bgImageAlt,
      prodImage: this.prodImage,
      prodImageAlt: this.prodImageAlt,
      seriesTitle: this.seriesTitle,
      seriesTitleTextColor: this.seriesTitleTextColor,
      seriesDescription: this.seriesDescription,
      seriesDescriptionTextColor: this.seriesDescriptionTextColor,
      exploreUrl: this.exploreUrl,
      exploreText: this.exploreText,
      exploreButtonColor: this.exploreButtonColor,
      exploreButtonTextColor: this.exploreButtonTextColor,
    };
  }
}
